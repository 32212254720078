<script lang="ts" setup>
import { reactive, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BadgeVariant, BreadcrumbTitles, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { DirectoryTableColumn } from '@components/directory/models/DirectoryTableModels';
import DirectoryTable from '@components/directory/DirectoryTable.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

enum TableHeaders {
  Views = 'View Name',
  Links = 'View Settings',
  RegisteredCamera = 'Registered Camera',
  Status = 'Change View Status',
  Delete = 'Delete',
}

interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, active: true },
];

// Set View Permissions
const applicationStore = useApplicationStore();
const viewStore = useViewStore();

// View Permissions
const showNew = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
// const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
// const showRegister = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);
const createView = applicationStore.canUser(client.Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);

const cameraLiveStatus = ref(new Map<string, boolean>());
const isLoading = ref<boolean>(false);

// Delete Modal
const showDeleteModal = ref<boolean>(false);
const selectedViewToDelete = ref<client.View | null>(null);

// Status Modal
const showStatusModal = ref<boolean>(false);
const selectedViewToUpdate = ref<client.View | null>(null);
const selectedViewStatus = ref<client.ViewStatus | null>(null);

// Dropdown Status
const statusOptions = [
  { label: 'Active', value: client.ViewStatus.ACTIVE },
  { label: 'Inactive', value: client.ViewStatus.INACTIVE },
  { label: 'Archive', value: client.ViewStatus.ARCHIVE },
];

// Handle Status Change & Open Modal
function handleStatusChange(row: client.View, newStatus: client.ViewStatus) {
  if (newStatus !== row.status) {
    showStatusConfirmModal(row, newStatus);
  }
}

// Table
const tableRef = ref<DirectoryTableType | null>(null);
const columns: DirectoryTableColumn[] = [
  {
    label: TableHeaders.Views,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    width: '98%',
  },
  {
    label: TableHeaders.Links,
    field: 'actions',
    headerClasses: 'text--white-space-nowrap',
    width: '1%',
  },
  {
    label: TableHeaders.RegisteredCamera,
    field: 'camera',
    headerClasses: 'text--white-space-nowrap',
    width: '1%',
  },
  {
    label: TableHeaders.Status,
    field: 'status',
    headerClasses: 'text--white-space-nowrap',
    width: '1%',
  },
];

// Add the delete column if permissions allow
if (showDelete) {
  columns.push({
    label: TableHeaders.Delete,
    field: 'delete',
    headerClasses: 'status',
    width: '1%',
  });
}

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

/**
 * Fetches all views and all cameras for the current organisation,
 * then maps the registered status of cameras to their corresponding views.
 * Uses camera IDs to determine if a view's camera is registered and updates the `cameraLiveStatus` map.
 *
 * @returns {Promise<client.View[]>} A promise that resolves to an array of views with their camera registration status mapped.
 */
async function updateViewCameraLiveStatus(): Promise<client.View[]> {
  isLoading.value = true;

  try {
    // Fetch views and cameras concurrently
    const [viewsResponse, camerasResponse] = await Promise.all([
      client.listViews({
        organisation: applicationStore.activeOrganisation!.id,
      } as client.ListViewsData),
      client.listCameras({
        organisation: applicationStore.activeOrganisation!.id,
      } as client.ListCamerasData),
    ]);

    const views = viewsResponse.data;
    const cameras = camerasResponse.data;

    // Sort the views by their creation date in descending order (newest views first)
    views.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    // Create a map of live cameras by their ID and registered status
    const liveCameras: Map<string, boolean> = new Map(
      cameras
        .filter((camera: client.Camera) => camera.status === client.CameraStatus.REGISTERED)
        .map((camera: client.Camera) => [camera.id, true]),
    );

    // Iterate over each view and update its camera live status in the `cameraLiveStatus` map
    views.forEach((view: client.View) => {
      // Skip undefined cameras
      if (view.camera) {
        const isRegistered = liveCameras.has(view.camera);
        cameraLiveStatus.value.set(view.camera, isRegistered);
      }
    });

    // Return the views with their live camera status
    return views;
  } catch (error) {
    console.error('Error fetching camera list or views:', error);
    // Return an empty array if an error occurs
    return [];
  } finally {
    isLoading.value = false;
  }
}


// View Status Badge Variant Map
const statusVariantMap = {
  [client.ViewStatus.ACTIVE]: { variant: BadgeVariant.Success, label: client.ViewStatus.ACTIVE },
  [client.ViewStatus.INACTIVE]: { variant: BadgeVariant.Danger, label: client.ViewStatus.INACTIVE },
  [client.ViewStatus.ARCHIVE]: { variant: BadgeVariant.Warning, label: client.ViewStatus.ARCHIVE },
};

function showDeleteConfirmModal(row: client.View): void {
  showDeleteModal.value = true;
  selectedViewToDelete.value = row;
}

function closeDeleteConfirmModal(): void {
  showDeleteModal.value = false;
  selectedViewToDelete.value = null;
}

async function deleteView(): Promise<void> {
  if (!selectedViewToDelete.value?.id) return;

  const result = await viewStore.deleteView(selectedViewToDelete.value.id);

  closeDeleteConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to delete view.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted view.',
    autoCloseMs: 3000,
  });

  // Reload the table on deletion
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}

function showStatusConfirmModal(row: client.View, newStatus: client.ViewStatus): void {
  selectedViewToUpdate.value = row;
  selectedViewStatus.value = newStatus;
  showStatusModal.value = true;
}

function confirmStatusChange(): void {
  if (selectedViewToUpdate.value && selectedViewStatus.value) {
    // Update the row's status after confirmation
    selectedViewToUpdate.value.status = selectedViewStatus.value;
    // Close the modal
    closeStatusConfirmModal();
  }
}

function closeStatusConfirmModal(): void {
  showStatusModal.value = false;
  selectedViewToUpdate.value = null;
  selectedViewStatus.value = null;
}

async function changeViewStatus(): Promise<void> {
  const viewId = selectedViewToUpdate.value?.id;
  const viewStatus = selectedViewToUpdate.value?.status;

  if (!viewId || !viewStatus) return;

  const isDeactivation = viewStatus === client.ViewStatus.ACTIVE;
  const result = isDeactivation
    ? await viewStore.deactivateView(viewId)
    : await viewStore.activateView(viewId);

  closeStatusConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to change view status.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully changed view status.',
    autoCloseMs: 3000,
  });

  // Reload the table
  await tableRef.value?.reloadData();
}

</script>

<template>
  <SubHeader heading="Views"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="createView"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewNew }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add View
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <DirectoryTable ref="tableRef"
                          :columns="columns"
                          :retrieve-data="updateViewCameraLiveStatus"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No views found"
                          :icon-name="IconName.CameraIcon"
                          :icon-style="IconStyle.Outline">
                <template v-if="showNew">
                  <p>Get started by registering your first view</p>
                  <ButtonComponent :to="{ name: PageNames.ViewNew }"
                                   :variant="ButtonVariant.Dark">
                    Add View
                  </ButtonComponent>
                </template>
              </EmptyState>
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.label === TableHeaders.Views">
                <div class="d-flex align-center gap-10">
                  <span>{{ row.name }}</span>
                  <BadgeComponent v-if="statusVariantMap[row.status as client.ViewStatus]"
                                  :is-pill="true"
                                  :variant="statusVariantMap[row.status as client.ViewStatus].variant">
                    {{ statusVariantMap[row.status as client.ViewStatus].label }}
                  </BadgeComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Links">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent v-if="row.id"
                                   :to="{ name: PageNames.ViewInformation, params: { id: row.id } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    View Info
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.RegisteredCamera">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent v-if="row.camera && cameraLiveStatus.get(row.camera)"
                                   :to="{ name: PageNames.CameraStatus, params: { id: row.camera } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    Camera Status
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Status">
                <v-select :model-value="row.status"
                          :options="statusOptions"
                          :clearable="false"
                          label="label"
                          :disabled="row.status === client.ViewStatus.ARCHIVE"
                          @option:selected="handleStatusChange(row, $event.value)" />
              </template>

              <template v-if="column.label === TableHeaders.Delete">
                <div class="d-flex justify-center gap-20">
                  <ButtonComponent v-if="showDelete"
                                   :variant="ButtonVariant.Danger"
                                   :size="ButtonSize.Small"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </DirectoryTable>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete View Modal -->
  <ModalComponent v-if="showDelete && selectedViewToDelete"
                  :visible="true"
                  heading-title="Delete View"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the view: <span class="text--bold">{{ selectedViewToDelete.name }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteView()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>


  <!-- Status View Modal -->
  <ModalComponent v-if="showStatusModal && selectedViewToUpdate"
                  :visible="true"
                  heading-title="Change View Status"
                  @confirm="confirmStatusChange"
                  @on-close="closeStatusConfirmModal">
    <template #modal-content>
      <p v-if="selectedViewStatus === client.ViewStatus.ARCHIVE">
        <span class="text--bold">Important:</span> Archiving this view is a permanent action.
        While the images on the view will still be available,
        <span class="text--bold">you will not be able to activate an archived view. You will need to create a new view.</span>
      </p>
      <p v-else>
        Are you sure you want to
        <span class="text--bold">
          {{ selectedViewStatus === client.ViewStatus.ACTIVE ? 'activate' : 'deactivate' }}
        </span>
        the view:
        <span class="text--bold">{{ selectedViewToUpdate.name }}</span>?
      </p>
    </template>

    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeStatusConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Success"
                       @click="changeViewStatus">
        Confirm
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
:deep(.table-wrapper) {
  margin-bottom: unset;
}
</style>
