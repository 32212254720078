<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { liveRelativeTime } from '@utils/timeUtils';
import { useMobileDetection } from '@utils/isMobile';
import { dateTimeFormat } from '@utils/date';
import { BadgeVariant, ImageSize } from '@viewModels/enums';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import Heading from '@components/Heading.vue';
import HorizontalRule from '@components/HorizontalRule.vue';
import BadgeComponent from '@components/BadgeComponent.vue';


// Stores
const applicationStore = useApplicationStore();
const { isMobile } = useMobileDetection();
const { activeUser } = applicationStore;

// Emits
const emit = defineEmits<{
  (e: 'showImageModal', view: client.View, liveLastCaptured: string, lastCapturedAt: string): void;
}>();

// Props
const props = defineProps<{
  view: client.View;
  showOnlyImage: boolean;
  cardMaxWidth: number;
}>();

// Prop References
const viewImageUrl = computed(() => props.view.latestImageURL);
const showOnlyImage = computed(() => props.showOnlyImage ?? false);
const viewIsPublic = ref(props.view.isPublic);
const cardMaxWidth = computed(() => props.cardMaxWidth);

// Data
const currentTime = ref(Date.now());
let interval: ReturnType<typeof setInterval> | undefined;

/**
 * Starts a real-time interval to update the current time every second.
 * The interval is cleared on component unmount to avoid memory leaks.
 */
onMounted(() => {
  interval = setInterval(() => {
    currentTime.value = Date.now();
  }, 1000);
});

/**
 * Clears the real-time interval when the component is unmounted.
 */
onUnmounted(() => {
  if (interval) clearInterval(interval);
});

/**
 * Computes the relative time between the captured timestamp (lastCapturedUtc)
 * and the current time. Updates every second based on the real-time clock.
 *
 * @returns {string} The relative time, such as "7 minutes ago" or "2 hours ago".
 * If the timestamp is not available, returns "Never".
 */
const liveLastCaptured = computed(() => {
  if (!props.view.lastCapturedUtc) return 'Never';
  const lastCapturedDate = new Date(props.view.lastCapturedUtc);
  return liveRelativeTime(lastCapturedDate, currentTime.value);
});

/**
 * Computes the static "at" timestamp for the captured time (lastCapturedUtc),
 * adjusted to the user's timezone for display.
 *
 * @returns {string} The formatted timestamp, such as "02/01/2025, 06:00:10 GMT-5".
 * If the timestamp is not available, returns an empty string.
 */
const lastCapturedAt = computed(() => {
  if (!props.view.lastCapturedUtc) return '';
  // Format the time in the user's timezone for display
  return dateTimeFormat(activeUser?.timezone).format(new Date(props.view.lastCapturedUtc));
});
</script>

<template>
  <div class="card" :style="{ '--card-max-width': `${cardMaxWidth}px` }">
    <div class="card__image" :class="{ 'card__image--show-only-image': showOnlyImage }">
      <BadgeComponent v-if="viewIsPublic && !showOnlyImage" class="card__image--badge" :variant="BadgeVariant.Warning">
        View is {{ viewIsPublic && 'PUBLIC' }}
      </BadgeComponent>
      <template v-if="viewImageUrl">
        <img :src="`${viewImageUrl}${isMobile ? ImageSize.Thumbnail : ImageSize.Medium}`"
             loading="lazy"
             aria-hidden="true"
             class="card__image--active"
             :alt="props.view.name"
             @click="emit('showImageModal', props.view, liveLastCaptured, lastCapturedAt)">
        <MagnifyingGlassIcon class="magnify-glass-icon" />
      </template>
      <template v-else>
        <p class="card__image--fallback">
          No image
        </p>
      </template>
    </div>

    <!-- Transition for card content -->
    <Transition name="slide-fade">
      <div v-if="!showOnlyImage" class="card__content">
        <Heading level="5" class="card__content--title">
          {{ props.view.name }}
        </Heading>

        <HorizontalRule :margin-block="10" :negative-margin-inline="15" />

        <p class="card__content--last-captured">
          Last Captured: {{ liveLastCaptured }} <span v-if="lastCapturedAt">at {{ lastCapturedAt }}</span>
        </p>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

$transition-duration: 300ms;
$transition-delay: 300ms;

:root {
  --transition-duration: #{$transition-duration};
  --transition-delay: #{$transition-delay};
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: var(--card-max-width);
  width: 100%;
  padding: 0 $gap-mobile 0 $gap-mobile;
  background-color: $neutral-100;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px $neutral-300;
  transition: transform $transition-duration ease, max-width $transition-duration ease-in-out;

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 3 / 2; // Use aspect ratio to stop CLS (Content Layout Shift)
    margin-inline: -15px;
    width: calc(100% + 30px);
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    background: $neutral-200 url('/src/assets/background-light.png') repeat center center;
    background-size: 25%;
    box-shadow: 0 0 0 1px $neutral-300;
    z-index: 1; // so the content slides behind the image
    transition: border-radius $transition-duration ease;

    &--badge {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
    }

    &--show-only-image {
       border-radius: 6px;
    }

    & img {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      object-fit: cover;
      border-radius: 6px 6px 0 0;
      transition: transform $transition-duration ease-in;
    }

    &:hover .magnify-glass-icon {
      opacity: 1;
      fill: $neutral-800;
    }

    & .magnify-glass-icon {
      position: absolute;
      inset: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
      fill: $neutral-50;
      width: 48px;
      height: 48px;
      transition: opacity $transition-duration ease-in, fill $transition-duration ease-in;
      pointer-events: none;
    }

    &--active {
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.7;
      }
    }

    &--fallback {
      padding-inline: $gap-desktop;
      font-size: .875rem;
      color: $neutral-600;
      text-align: center;
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: $gap-mobile;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-sm) {
        flex-basis: 2.4em;
      }
    }

    &--last-captured {
      flex-grow: 1;
      font-size: 0.875rem;
      margin-bottom: $gap-mobile;
    }
  }
}
</style>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>

