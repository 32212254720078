<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useImageStore } from '@stores/image';
import * as client from '@gabrielcam/api-client';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import BatchImageDirectoryPagination from '@components/gallery/BatchImageDirectoryPagination.vue';
import VideoDirectoryFilter from '@components/video/VideoDirectoryFilter.vue';
import EmptyState from '@layouts/EmptyState.vue';
import DownloadCard from '@components/cards/DownloadCard.vue';
import HorizontalRule from '@components/HorizontalRule.vue';
import { useApplicationStore } from '@stores/application';

const applicationStore = useApplicationStore();
const imageStore = useImageStore();
const { zips } = storeToRefs(imageStore);
const route = useRoute();

const selectedFilters = ref<{ client: string | undefined }>({
  client: undefined,
});
const viewId = ref<string>();
const sortBy = ref<string | undefined>();
const isLoading = ref<boolean>(true);

/**
 * Loads downloads for a given view ID, optionally sorting by a specified value.
 *
 * @param id The ID of the view to load downloads for.
 * @param sortByValue The value to sort the downloads by. If not provided, no sorting will be applied.
 * @returns A promise that resolves when the downloads have been loaded.
 */
async function loadDownloads(id: string, sortByValue?: string): Promise<void> {
  isLoading.value = true;
  await imageStore.getDownloads(id, sortByValue);
  isLoading.value = false;
}

/**
 * Updates the sort order of the downloads list.
 *
 * @param newSortBy The new sort order value.
 * @returns A promise that resolves when the downloads list has been updated.
 */
async function updateSort(newSortBy: string): Promise<void> {
  if (!viewId.value) return;
  sortBy.value = newSortBy;
  await loadDownloads(viewId.value, newSortBy);
}

/**
 * Deletes a batch zip by its ID and updates the downloads list.
 *
 * @param batchDownload The ID of the batch download to delete.
 * @returns A promise that resolves when the deletion is complete.
 */
async function deleteZip(batchDownload: string): Promise<void> {
  try {
    client.deleteBatchDownloadById({ batchDownloadId: batchDownload });
    applicationStore.publishSuccessNotification({
      text: 'Batch zip successfully deleted.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    console.error('An error occurred while deleting the batch zip.', error);
    applicationStore.publishErrorNotification({
      text: 'An error occurred while trying to delete the batch zip.',
    })
  } finally {
    // Add a small delay to allow backend to process the deletion
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await loadDownloads(viewId.value as string);
  }
}

/**
 * Watches for changes to the route parameter 'id' and loads downloads accordingly.
 */
watch(
  () => route.params['id'],
  (id) => {
    if (id) loadDownloads(id as string, sortBy.value);
  }
);

/**
 * Initialise the viewId and load the downloads for the given viewId.
 */
onMounted(async () => {
  viewId.value = route.params['id'] as string | undefined;
  if (viewId.value) await loadDownloads(viewId.value);
});
</script>

<template>
  <ContainerCard>
    <div>
      <VideoDirectoryFilter @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                            @sort-view-by="(value: string) => updateSort(value)" />

      <Directory :object-collection-reference="zips.data"
                 :loading="isLoading">
        <template #default="scope">
          <DownloadCard :resource="scope.resource"
                        @on-delete="(resource: unknown) => deleteZip(resource as string)" />
        </template>
        <template #table-empty>
          <EmptyState heading-text="No downloads found"
                      :icon-name="IconName.FolderArrowDownIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </Directory>

      <HorizontalRule />

      <BatchImageDirectoryPagination v-if="viewId"
                                     :view-id="viewId" />
    </div>
  </ContainerCard>
</template>
