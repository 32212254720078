<script setup lang="ts">
// Define the props
const props = defineProps<{
  type?: 'solid' | 'dashed' | 'dotted';
  variant?: 'light' | 'dark' | 'transparent';
  height?: number;
  marginBlock?: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
  negativeMarginInline?: 0 | 5 | 10 | 15 | 20 | 25 | 30;
}>();

// Default values
const type = props.type ?? 'solid';
const variant = props.variant ?? 'light';
const height = props.height ?? 1;
const marginBlockValue = props.marginBlock ?? 30;
</script>

<template>
  <hr :class="[variant, type]"
      :style="[
        { marginInline: '-' + negativeMarginInline + 'px' },
        { marginBlock: marginBlockValue + 'px' },
        type !== 'dashed' ? { borderTopWidth: height + 'px' } : {},
        negativeMarginInline ? { width: `calc(100% + ${negativeMarginInline * 2}px)` } : {}
      ]">
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

hr {
  width: 100%;
  border: none;

  &.light {
    border-top-color: $neutral-300;
  }

  &.dark {
    border-top-color: $neutral-800;
  }

  &.transparent {
    border-top-color: transparent;
  }

  &.solid {
    border-top-style: solid;
  }

  &.dashed {
    border-top-style: dashed;
  }

  &.dotted {
    border-top-style: dotted;
  }
}
</style>
