<script lang="ts" setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useApplicationStore } from '@stores/application';
import * as client from '@gabrielcam/api-client';
import timezones, { TimeZone } from 'timezones-list';
import { AlertVariant, ButtonType, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import AlertBanner from '@components/AlertBanner.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';

type schedule = {
  "job": "capture",
  "cron": string,
  "timezone": string,
  "active": boolean
}

const props = defineProps<{ cameraId: string }>();
const applicationStore = useApplicationStore();
const shadow = ref<client.Shadow>();
const reported = ref<schedule>();
const desired = ref<schedule>();
const cronExpression = ref<string>();
const cronEndExpression = ref<string>();
const cronActive = ref<boolean>();
const isSubmitting = ref<boolean>(false);
const isFetching = ref<boolean>(false);
const hours = Array.from({ length: 25 }, (_, i) => String(i).padStart(2, '0'));
const captureRates = [
  { label: "5 seconds", cron: "*/5 *" },
  { label: "10 seconds", cron: "*/10 *" },
  { label: "15 seconds", cron: "*/15 *" },
  { label: "20 seconds", cron: "*/20 *" },
  { label: "30 seconds", cron: "*/30 *" },
  { label: "1 minute", cron: "0 */1" },
  { label: "2 minutes", cron: "0 */2" },
  { label: "3 minutes", cron: "0 */3" },
  { label: "4 minutes", cron: "0 */4" },
  { label: "5 minutes", cron: "0 */5" },
  { label: "6 minutes", cron: "0 */6" },
  { label: "10 minutes", cron: "0 */10" },
  { label: "12 minutes", cron: "0 */12" },
  { label: "15 minutes", cron: "0 */15" },
  { label: "20 minutes", cron: "0 */20" },
  { label: "30 minutes", cron: "0 */30" },
  { label: "1 hour", cron: "0 0" }
];
const daysOfWeek = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" }
];

const { handleSubmit, defineField, setFieldValue } = useForm({
  initialValues: {
    startHour: '07',
    endHour: '19',
    captureRate: { label: "10 minutes", cron: "0 */10" },
    selectedDays: [],
    timezone: 'Europe/London',
  },
  validationSchema: yup.object({
    startHour: yup.number().required('Please specify an Start Hour.')
      .test(
        'is-less-than-end',
        'Start Hour must be earlier than the End Hour.',
        function (value) {
          const { endHour } = this.parent;
          return Number(value) < Number(endHour);
        }
      ),
    endHour: yup.number().required('Please specify an End Hour.')
      .test(
        'is-greater-than-start',
        'End Hour must be later than the Start Hour.',
        function (value) {
          const { startHour } = this.parent;
          return Number(value) > Number(startHour);
        }
      ),
  }),
});

const [startHourValue] = defineField('startHour');
const [endHourValue] = defineField('endHour');
const [timezoneValue] = defineField('timezone');
const [captureRateValue] = defineField('captureRate');
const [selectedDaysValue] = defineField('selectedDays');

const onSubmit = handleSubmit(async (values): Promise<void> => {
  isSubmitting.value = true;
  try {

    let schedules: schedule[] = [];
    schedules.push({
      job: 'capture',
      cron: cronExpression.value!,
      timezone: values.timezone,
      active: cronActive.value!,
    });

    if (cronEndExpression.value) {
      schedules.push(
        {
          job: 'capture',
          cron: cronEndExpression.value,
          timezone: values.timezone,
          active: cronActive.value!,
        },
      )
    }

    shadow.value = await client.updateCameraByIdShadow({
      cameraId: props.cameraId,
      requestBody: {
        state: {
          desired: {
            schedule: schedules,
          },
        },
      },
    })
    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Update request sent.',
      autoCloseMs: 3000,
    });
  } catch {
    applicationStore.publishErrorNotification({
      text: 'Error while updating.'
    });
    isSubmitting.value = false;
    return;
  }
});

const captureScheduleState = (schedules: schedule[]): schedule | undefined => schedules.find((schedule: schedule) => schedule.job === 'capture')
const fetchShadow = async (): Promise<void> => {
  isFetching.value = true;
  shadow.value = await client.getCameraByIdShadow({ cameraId: props.cameraId })
  isFetching.value = false;
}

watchEffect(() => {
  if (shadow.value?.state.desired && 'schedule' in shadow.value?.state.desired) {
    desired.value = captureScheduleState(shadow.value.state.desired['schedule'] as schedule[]);
  }
  if (shadow.value?.state.reported && 'schedule' in shadow.value?.state.reported) {
    reported.value = captureScheduleState(shadow.value.state.reported['schedule'] as schedule[]);
  }
  const cronValue = desired.value || reported.value;

  if (cronValue) {
    const cronParts = cronValue.cron.split(' ')
    if (cronParts[0] && cronParts[1]) {
        const matched = captureRates.find((rate) => rate.cron.toString() === `${cronParts[0]} ${cronParts[1]}`)
        setFieldValue('captureRate', matched!);
    }

    if (cronParts[2]) {
      const hourField = cronParts[2];
      let startHour;
      let endHour;
      if (hourField?.includes('-')) {
        const splitHours = hourField.split('-');
        startHour = splitHours[0]?.padStart(2, '0');
        endHour = (Number(splitHours[1])+1).toString().padStart(2, '0');
      } else {
        startHour = hourField.padStart(2, '0');
        endHour = (Number(hourField)+1).toString().padStart(2, '0');
      }
      setFieldValue('startHour', startHour!);
      setFieldValue('endHour', endHour!);
    }

    if (cronParts[5]) {
      const dayOfWeekField = cronParts[5];
      const daysArray = dayOfWeekField === '*'
        ? []
        : dayOfWeekField.split(',').map(String);
      setFieldValue('selectedDays', daysArray as never);
    }
  }
})

watchEffect(() => {
  if (!(captureRateValue.value && startHourValue.value && endHourValue.value)) {
    return;
  }

  const adjustedEndHour = Number(endHourValue.value) - 1;
  const hourField = Number(startHourValue.value) == adjustedEndHour
    ? Number(startHourValue.value)
    : `${Number(startHourValue.value)}-${adjustedEndHour}`;

  const dayOfMonthField = '*';
  const monthField = '*';

  const dayOfWeekField = selectedDaysValue.value?.length
    ? selectedDaysValue.value.toString()
    : '*';

  cronActive.value = !!selectedDaysValue.value?.length;
  cronExpression.value = `${captureRateValue.value.cron} ${hourField} ${dayOfMonthField} ${monthField} ${dayOfWeekField}`

  // We always want the final capture to take place on the End Hour, so create another cron expression that will
  // ultimately take just 1 picture at the end
  const finalHour = Number(endHourValue.value) === 24 ? 0 : Number(endHourValue.value);
  cronEndExpression.value = finalHour === 0 && Number(startHourValue.value) === 0
    ? undefined
    : `0 0 ${finalHour} ${dayOfMonthField} ${monthField} ${dayOfWeekField}`

  console.group('cron info');
  console.log(`cron: ${cronExpression.value}`);
  console.log(`end cron: ${cronEndExpression.value ? cronEndExpression.value : 'If the start hour is 0 and end hour is 24, then there is no end cron'}`);
  console.log(`active: ${cronActive.value}`);
  console.groupEnd();
})

onMounted(async () => await fetchShadow())
const isPending = computed((): boolean => !!desired.value && JSON.stringify(reported.value) != JSON.stringify(desired.value));
</script>

<template>
  <div class="container-card">
    <AlertBanner v-if="isPending"
                 :variant="AlertVariant.Warning"
                 :has-bottom-margin="true"
                 :icon-name="IconName.InformationCircleIcon"
                 :icon-style="IconStyle.Outline">
      <template #mainContent>
        <span class="text--size-5">
          <Heading level="5">
            Changes are pending
          </Heading>
          The settings below have been sent to the device and are awaiting confirmation that they have been accepted.
          Click refresh to check if the update has been applied.
        </span>
      </template>
    </AlertBanner>
    <form @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Camera Schedule
          </Heading>
          <p>
            Choose the days, times & rate that you'd like the camera to take photos.
          </p>
        </div>

        <Loading v-if="isFetching" />

        <div v-else class="fields">
          <div class="row-half">
            <div class="field">
              <label for="timezone">Timezone</label>
              <v-select id="timezone"
                        v-model="timezoneValue"
                        :clearable="false"
                        :disabled="isPending"
                        :reduce="(timezone: TimeZone) => timezone.tzCode"
                        :options="timezones" />
              <ErrorMessage name="timezone" class="message-error message" as="p" />
            </div>
          </div>

          <div class="row-quarter">
            <div class="field">
              <label for="start-hour">Start Hour</label>
              <!-- Start Hour -->
              <v-select id="start-hour"
                        v-model="startHourValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="hours" />
              <ErrorMessage name="startHour" class="message-error message" as="p" />
            </div>

            <div class="field">
              <!-- End Hour -->
              <label for="end-hour">End Hour</label>
              <v-select id="end-hour"
                        v-model="endHourValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="hours" />
              <ErrorMessage name="endHour" class="message-error message" as="p" />
            </div>

            <div class="field">
              <!-- Capture Rate -->
              <label for="capture-rate">Capture Rate</label>
              <v-select id="end-hour"
                        v-model="captureRateValue"
                        :clearable="false"
                        :disabled="isPending"
                        :options="captureRates" />
              <ErrorMessage name="captureRate" class="message-error message" as="p" />
            </div>
          </div>

          <div class="row">
            <div v-for="(day, index) in daysOfWeek" :key="index" class="checkbox-field">
              <label :for="day.value">{{ day.label }}</label>
              <Field :id="day.value"
                     v-model="selectedDaysValue"
                     :disabled="isPending"
                     name="days"
                     type="checkbox"
                     :value="day.value" />
            </div>
          </div>
        </div>
      </div>

      <ButtonContainer>
        <ButtonComponent v-if="isPending"
                         :variant="ButtonVariant.Dark"
                         :is-block-btn="true"
                         :type="ButtonType.Button"
                         :loading="isFetching"
                         :disabled="isFetching"
                         @click="fetchShadow">
          Refresh
        </ButtonComponent>

        <ButtonComponent v-if="!(isPending || isFetching)"
                         :variant="ButtonVariant.Dark"
                         :type="ButtonType.Submit"
                         :loading="isSubmitting"
                         :disabled="isSubmitting || isFetching">
          Update
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </div>
</template>

