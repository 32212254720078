<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { BreadcrumbTitles, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableColumn } from '@components/table/models/Table';
import TLSTable from '@components/table/TLSTable.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

const enum TableHeaders {
  Name = 'Name',
  Actions = 'Actions',
}

const applicationStore = useApplicationStore();

const clientList = ref<client.ListClientsResponse | null>(null);
const clientName = ref('');
const showDeleteClientModel = ref(false);
const deleteClientModelFunc = ref<Function>(() => deleteClient);
const deleting = ref(false);

const isLoading = ref<boolean>(true);

const showNew = applicationStore.canUser(client.Entitlements.CREATE_CLIENT, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CLIENT, applicationStore.activeOrganisation!);
const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CLIENT, applicationStore.activeOrganisation!);



/**
 * Retrieves a list of projects for the active organisation.
 *
 * @returns {Promise<client.ListProjectsResponse | null>} A promise that resolves to the project list response object or null on error.
 */
async function getClientList(): Promise<client.ListClientsResponse | null> {
  try {
    const res = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
    clientList.value = res;
    return res;
  } catch (error) {
    console.error("Error fetching project list:", error);
    return null;
  }
}

onMounted(async () => {
  isLoading.value = true;
  clientList.value = await getClientList();
  isLoading.value = false;
});


async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteClientModel.value = false;
  deleting.value = false;
}

async function showDeleteConfirmModal(row: client.Client): Promise<void> {
  showDeleteClientModel.value = true;
  clientName.value = row.name;

  deleteClientModelFunc.value = async (): Promise<void> => {
    await deleteClient(row);
  };
}

async function deleteClient(row: client.Client): Promise<void> {
  deleting.value = true;
  try {
    await client.deleteClientById({ clientId: row.id });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
      return;
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    deleting.value = false;
  }

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted client.',
    autoCloseMs: 3000,
  });

  // Refresh the table
  isLoading.value = true;
  await getClientList();
  isLoading.value = false;
}

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    headerClasses: 'status',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllClients, active: true },
];
</script>

<template>
  <SubHeader :heading="BreadcrumbTitles.AllClients"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :to="{ name: PageNames.ClientCreate }"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add Client
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Loading v-if="isLoading" />

    <template v-else>
      <TLSTable :data="clientList?.data || []"
                :is-loading="isLoading"
                :columns="table.columns"
                :total="table.totalRecordCount"
                :sortable="table.sortable">
        <template #table-empty>
          <EmptyState heading-text="No clients found"
                      strap-line="Add a new client"
                      :button-variant="ButtonVariant.Dark"
                      button-text="Add Client"
                      :icon-name="IconName.UserGroupIcon"
                      :icon-style="IconStyle.Outline"
                      :to="{ name: PageNames.ClientCreate }" />
        </template>

        <template #cell="{ row, column }">
          <template v-if="column.label === TableHeaders.Actions">
            <div class="d-flex gap-20">
              <ButtonComponent v-if="showUpdate"
                               :to="{ name: PageNames.ClientSettings, params: { id: row.id } }"
                               :size="ButtonSize.Small"
                               :variant="ButtonVariant.Dark">
                Edit
              </ButtonComponent>
              <ButtonComponent v-if="showDelete"
                               :size="ButtonSize.Small"
                               :variant="ButtonVariant.Danger"
                               @click="showDeleteConfirmModal(row)">
                Delete
              </ButtonComponent>
            </div>
          </template>
        </template>
      </TLSTable>
    </template>
  </ContainerCard>

  <!-- Delete Client Modal -->
  <ModalComponent :visible="showDeleteClientModel"
                  heading-title="Delete Client"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--bold">{{ clientName }}</span> client?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Button"
                       :is-block-btn="true"
                       :loading="deleting"
                       :disabled="deleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteClientModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
:deep(.table-wrapper) {
  margin-bottom: unset;
}
</style>
