<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { LogOrderByFields, Resources } from '@gabrielcam/api-client';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { BadgeVariant } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import { dateTimeFormat } from '@utils/date';
import { useApplicationStore } from '@stores/application';
import ResourcePagination from '@components/ResourcePagination.vue';
import Heading from '@components/Heading.vue';

const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;
const route = useRoute();
const cameraId = route.params['id'] as string;
const page = ref(1);
const limit = ref(24);
const isLoading = ref<boolean>(false);

const logs = ref<Logs | null>(null);
interface Logs {
  data: client.Log[];
  total_count: number;
  has_more: boolean;
}

const fetch = async (): Promise<void> => {
  try {
    isLoading.value = true;

    const { data, total_count, has_more } = await client.listLogsByResourceAndId({
      resource: Resources.CAMERA,
      id: cameraId,
      page: page.value,
      limit: limit.value,
      sort: 'desc',
      orderBy: LogOrderByFields.TIMESTAMP,
    });

    logs.value = { data, total_count, has_more };

  } catch (error) {
    console.error('Error fetching camera logs:', error);
  } finally {
    isLoading.value = false;
  }
};

const getLogIndex = (index: number): number => {
  return logs.value ? logs.value.total_count - ((page.value - 1) * limit.value) - index : 0;
};

function getBadgeVariant(status: client.LogLevel): BadgeVariant {
  const variantMap: { [key in client.LogLevel]: BadgeVariant } = {
    INFO: BadgeVariant.Info,
    WARN: BadgeVariant.Warning,
    ERROR: BadgeVariant.Danger,
  };
  return variantMap[status];
}

onMounted( () => fetch());
watch([limit, page], () => fetch())
</script>

<template>
  <ContainerCard>
    <Heading level="3" :has-bottom-margin="true">
      Logs
    </Heading>

    <Loading v-if="isLoading" />
    <template v-else>
      <template v-if="logs?.total_count">
        <div class="log-grid-container">
          <div class="log-grid">
            <div v-for="(item, index) in logs?.data" :key="index" class="log-grid__item">
              <div class="log-grid__index">
                <BadgeComponent :variant="BadgeVariant.Light">
                  {{ getLogIndex(index) }}
                </BadgeComponent>
                <span class="log-grid__value">
                  {{ item.message }}
                </span>
              </div>

              <div class="log-grid__info">
                <BadgeComponent class="log-grid__timestamp" :variant="BadgeVariant.Light">
                  {{ dateTimeFormat(activeUser?.timezone).format(new Date(item.timestamp)) }}
                </BadgeComponent>

                <BadgeComponent class="log-grid__badge"
                                :is-pill="true"
                                :variant="getBadgeVariant(item.level) as BadgeVariant">
                  {{ item.level }}
                </BadgeComponent>
              </div>
            </div>
          </div>
        </div>

        <ResourcePagination :total="logs.total_count"
                            :page="page"
                            :limit="limit"
                            @limit-changed="(e: number) => {page = 1; limit = e}"
                            @page-changed="(e: number) => page = e" />
      </template>
      <template v-else>
        No log data available.
      </template>
    </template>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.log-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: 14px;

  &-container {
    container-type: inline-size;
    container-name: log;
    margin-bottom: 30px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 5px 20px;
    padding: 10px 0;
    border-top: 1px solid $neutral-200;

    &:nth-child(1) {
      border-top: 0;
    }
  }

  &__index {
    display: flex;
    gap: 10px;
  }

  &__info {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  &__badge {
    min-width: 60px;
    margin-left: auto;
  }

  &__timestamp {
    min-width: 150px;
    white-space: nowrap;
  }

  &__value {
    word-break: break-all;
    overflow-wrap: break-word;
  }

  // Using a @container to limit the width because of the sidenav
  @container log (min-width: 800px) {
    padding: 10px 20px;
    border: 1px solid $neutral-400;
    border-radius: 8px;

    &__item {
      flex-direction: row;
    }

    &__index {
      flex-grow: 1;
      display: flex;
      gap: 10px;
    }

    &__info {
      justify-content: flex-start;
    }
  }
}
</style>
