<template>
  <footer>
    <p class="text--center mb-0">
      &copy; {{ new Date().getFullYear() }} GabrielCam Ltd. All rights reserved.
    </p>
  </footer>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

footer {
  font-size: 0.875rem;
}
</style>
