<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, CardVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const route = useRoute();
const applicationStore = useApplicationStore();
const organisation = ref();
const organisationId = route.params['id'] as string;

const hardwareApiValue = ref();
const isToggleHardwareApi = ref(false);

const awsLambdaServiceValue = ref();
const isToggleAwsLambdaService = ref(false);

const imageServiceValue = ref();
const isToggleImageService = ref(false);

const toggleImageService = async (): Promise<void> => {
  isToggleImageService.value = true;
  organisation.value = imageServiceValue.value
    ? await client.organisationByIdDisableImageService({ organisationId })
    : await client.organisationByIdEnableImageService({ organisationId })
  processOrganisationData();
  isToggleImageService.value = false;
}

const toggleAwsLambdaService = async (): Promise<void> => {
  isToggleAwsLambdaService.value = true;
  organisation.value = awsLambdaServiceValue.value
    ? await client.organisationByIdDisableAwsLambdaService({ organisationId })
    : await client.organisationByIdEnableAwsLambdaService({ organisationId })
  processOrganisationData();
  isToggleAwsLambdaService.value = false;
}

const toggleHardwareApi = async (): Promise<void> => {
  isToggleHardwareApi.value = true;
  organisation.value = hardwareApiValue.value
    ? await client.organisationByIdDisableHardwareApi({ organisationId })
    : await client.organisationByIdEnableHardwareApi({ organisationId })
  processOrganisationData();
  isToggleHardwareApi.value = false;
}

const fetchOrganisationData = async (): Promise<void> => {
  organisation.value = await client.getOrganisationById({ organisationId });
  processOrganisationData();
};

const processOrganisationData = (): void => {
  hardwareApiValue.value = organisation.value.flags?.HARDWARE_API ?? false;
  awsLambdaServiceValue.value = organisation.value.flags?.AWS_LAMBDA_SERVICE ?? false;
  imageServiceValue.value = organisation.value.flags?.IMAGE_SERVICE ?? false;
}

onMounted(async () => {
  await fetchOrganisationData();
});
</script>

<template>
  <ContainerCard v-if="applicationStore.adminMode && organisation" :variant="CardVariant.Dark" :has-border="true">
    <Heading :has-bottom-margin="true" level="3">
      Organisation Admin Panel
    </Heading>
    <p>
      Configure capabilities for an Organisation
    </p>

    <ButtonContainer justify-content="space-between" has-bottom-margin>
      <div>
        <h4>
          Image Service
        </h4>
        <p>
          <i>Allows the Image Worker in Cloudflare to access the organisations Views, typically always required.</i>
        </p>
      </div>

      <ButtonComponent :variant="ButtonVariant.Light"
                       :loading="isToggleImageService"
                       :is-block-btn="true"
                       :onclick="toggleImageService"
                       :type="ButtonType.Button">
        {{ imageServiceValue ? 'Disable' : 'Enable' }}
      </ButtonComponent>
    </ButtonContainer>

    <ButtonContainer justify-content="space-between" has-bottom-margin>
      <div>
        <h4>
          AWS Lambda Service
        </h4>
        <p>
          <i>Allows AWS Lambda to send images to the organisations Views (only applicable for "live" clients with images in S3 buckets).</i>
        </p>
      </div>

      <ButtonComponent :variant="ButtonVariant.Light"
                       :loading="isToggleAwsLambdaService"
                       :is-block-btn="true"
                       :onclick="toggleAwsLambdaService"
                       :type="ButtonType.Button">
        {{ awsLambdaServiceValue ? 'Disable' : 'Enable' }}
      </ButtonComponent>
    </ButtonContainer>

    <ButtonContainer justify-content="space-between" has-bottom-margin>
      <div>
        <h4>
          Hardware API
        </h4>
        <p>
          <i>Allows the Hardware API to talk to the API, must be enabled if the organisation has GabrielCam systems.</i>
        </p>
      </div>

      <ButtonComponent :variant="ButtonVariant.Light"
                       :loading="isToggleHardwareApi"
                       :is-block-btn="true"
                       :onclick="toggleHardwareApi"
                       :type="ButtonType.Button">
        {{ hardwareApiValue ? 'Disable' : 'Enable' }}
      </ButtonComponent>
    </ButtonContainer>
  </ContainerCard>
</template>
