<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import { ButtonType, ButtonVariant, CardVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

interface ViewUpdateOriginForm {
  bucket?: string;
  prefix?: string;
}

const schema = yup.object({
  bucket: yup.string().required(),
  prefix: yup.string().required(),
});

const { handleSubmit } = useForm<ViewUpdateOriginForm>({
  validationSchema: schema,
});

const { value: bucketValue, errorMessage: bucketError } = useField<string | undefined>('bucket', 'bucket');
const { value: prefixValue, errorMessage: prefixError } = useField<string | undefined>('prefix', 'prefix');

const route = useRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();
const viewId = route.params['id'] as string;
const isSubmitting = ref<boolean>(false);
const currentView = ref<View>();

const onSubmitViewOrigin = handleSubmit(async (values) => {
  isSubmitting.value = true;

  const request: client.SetViewOriginRequest = {
    primary: true,
    provider: client.provider.AWS,
    region: 'eu-west-1',
    bucket: values.bucket ?? '',
    prefix: values.prefix ?? '',
    credentialId: '002',
  };

  const response = await viewStore.updateViewOrigin(viewId, request);
  isSubmitting.value = false;

  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully updated view.',
    autoCloseMs: 3000,
  });
});

const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
  bucketValue.value = currentView.value.origins?.[0]?.bucket;
  prefixValue.value = currentView.value.origins?.[0]?.prefix!;
};

onMounted(async () => {
  await fetchViewData();
});
</script>

<template>
  <ContainerCard v-if="applicationStore.adminMode" :variant="CardVariant.Dark" :has-border="true">
    <form @submit="onSubmitViewOrigin">
      <Heading :has-bottom-margin="true" level="3">
        View Origin Admin Panel
      </Heading>
      <p>Set a views AWS origin for {{ currentView?.name }}</p>
      <p>Used by GabrielCam to know where to retrieve the images from when located on AWS</p>
      <p>
        If this is an active project with existing images in AWS, the steps should be:
        <br>1. Configure view source
        <br>2. Import view source
        <br>3. Configure view origin
      </p>

      <div class="field-group">
        <div class="fields">
          <div class="field">
            <label for="origin-bucket">Bucket</label>
            <input id="origin-bucket"
                   v-model="bucketValue"
                   type="text">
            <p class="message message-error">
              {{ bucketError }}
            </p>
          </div>
          <div class="field">
            <label for="origin-prefix">Prefix</label>
            <input id="origin-prefix"
                   v-model="prefixValue"
                   type="text">
            <p class="message message-error">
              {{ prefixError }}
            </p>
          </div>
        </div>
      </div>

      <ButtonContainer>
        <ButtonComponent :variant="ButtonVariant.Light"
                         :loading="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Update Origin
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>
</template>
