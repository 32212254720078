<script setup lang="ts">
import { ref, watch } from 'vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import { useMobileDetection } from '@utils/isMobile';
import ViewerDatePicker from '@components/viewer/ViewerDatePicker.vue';
import { ButtonVariant, DatePickerTheme, ImageSize } from '@viewModels/enums';
import { ViewerImage } from '@/types';
import { vIntersectionObserver } from '@vueuse/components';
import { useIrisViewerStore } from '@stores/irisViewerStore';
import Loading from '@components/Loading.vue';

const irisStore = useIrisViewerStore();
const { isMobile } = useMobileDetection();
const imageElements = ref<Map<string, HTMLElement>>(new Map<string, HTMLElement>());

watch(
  () => irisStore.selectedImage,
  () => {
    const selectedImageElement = imageElements.value.get(irisStore.selectedImage!.id);
    if (!selectedImageElement) {
      console.warn('Image element not defined');
      return;
    }
    selectedImageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  },
);

function addImageElement(id: string, element: HTMLElement | undefined): void {
  if (element) imageElements.value.set(id, element);
}

function scrollCatalogue(direction: 'up' | 'down'): void {
  const catalogue = document.querySelector<HTMLDivElement>('div.viewer__catalogue--track');
  if (!catalogue) return;
  const scrollAmount = catalogue.offsetHeight * 0.9;
  if (direction === 'up') {
    catalogue.scrollTop -= scrollAmount;
  } else if (direction === 'down') {
    catalogue.scrollTop += scrollAmount;
  }
}

function selectImage(image: ViewerImage): void {
  if (!image) return;
  irisStore.setSelectedImage(image)
}

const scrollArea = ref()
function onIntersectionObserver([entry]: IntersectionObserverEntry[]): void {
  if (entry?.isIntersecting) {
    const id = entry.target.getAttribute('id')
    const image = entry.target.getAttribute('data-img-src')
    document.getElementById(id!)!.setAttribute('src', image!)
  }
}
</script>

<template>
  <div v-if="irisStore.capturedDates.length" class="viewer__catalogue">
    <ViewerDatePicker :variant="isMobile ? DatePickerTheme.Light : DatePickerTheme.Dark" />

    <Loading v-if="irisStore.loadingImages || irisStore.images.length === 0" />
    <div v-else
         id="scrollArea"
         ref="scrollArea"
         class="viewer__catalogue--track">
      <ButtonComponent class="track__button track__button--scroll-up"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Primary"
                       :icon-name="IconName.ChevronUpIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Scroll Up"
                       @click="scrollCatalogue('up')" />

      <section class="track__images">
        <div v-for="image in irisStore.images"
             :id="'track__images--item-' + image.id"
             :key="image.id"
             :ref="(e: any) => addImageElement(image.id, e)"
             class="track__images--item"
             :class="{ 'active-item': irisStore.selectedImage?.id == image.id }"
             @click="selectImage(image)">
          <img :id="'image' + image.id"
               v-intersection-observer="[onIntersectionObserver, { root: scrollArea, rootMargin: '100px' }]"
               src="/src/assets/background-light.png"
               :data-img-src="`${image.sourceURL}${ImageSize.Thumbnail}`"
               aria-hidden="true"
               :alt="image.originalFileName">
          <p class="track__date">
            {{ image.formattedTime }}
          </p>
        </div>
      </section>

      <ButtonComponent class="track__button track__button--scroll-down"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Primary"
                       :icon-name="IconName.ChevronDownIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Scroll Down"
                       @click="scrollCatalogue('down')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.viewer__catalogue {
  display: flex;
  flex-direction: column;
  height: calc(100% - 180px); // 80px is the height of the mobile menu
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    height: 100%;
    margin-bottom: unset;
  }

  &--track {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    overflow-y: auto;
    scroll-behavior: smooth;

    @media screen and (min-width: $breakpoint-lg) {
      gap: 10px;
      margin-bottom: 0;
    }

    & .track__images {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;

      @media screen and (min-width: $breakpoint-lg) {
        position: relative;
        top: -50px;
        gap: 10px;
        width: 100%;
        padding: 0 10px;
      }

      &--item {
        position: relative;
        display: inline-block;
        width: 100%;
        max-width: min(320px, 46%);
        margin: 0;
        overflow: hidden;
        line-height: 0;
        cursor: pointer;
        background: $neutral-200 url('/src/assets/background-light.png') repeat center center;
        background-size: 25%;
        aspect-ratio: 3 / 2;
        border: none;
        box-shadow: inset 0 0 0 1px var(--primary-color, $primary-color);

        @media screen and (min-width: $breakpoint-lg) {
           max-width: 100%;
        }

        img {
          width: 100%;
          transition: all 0.3s ease;
        }

        @media screen and (min-width: $breakpoint-lg) {

          &:last-of-type {
            margin-bottom: -36px;
          }
        }

        &.active-item,
        &:active,
        &:hover {
          img {
            transform: scale(1.1);
          }

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            content: '';
            border: 4px solid var(--secondary-color, $secondary-color);
          }
        }
      }
    }

    & .track__date {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: clamp(5px, 1.5vw, 7px);
      margin: 0;
      font-size: 0.675rem;
      line-height: 1;
      color: $neutral-50;
      background: $black-opacity-75;
      border-radius: 5px;
      opacity: 0.9;

      @media screen and (min-width: $breakpoint-lg) {
        font-size: 0.8rem;
      }
    }

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background: $black-opacity-50;
      border-right: 1px solid $white-opacity-25;
      border-left: 1px solid $white-opacity-25;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--secondary-color, $secondary-color);
      border-radius: 10px;
    }

    & .track__button {
      position: absolute;
      top: 0;
      z-index: 2;
      display: none;
      border-radius: 0 0 5px 5px;

      @media screen and (min-width: $breakpoint-lg) {
        position: sticky;
        display: block;
      }

      &--scroll-down {
        position: fixed;
        top: auto;
        bottom: 0;
        display: none;
        margin-left: -10px;
        border-radius: 5px 5px 0 0;

        @media screen and (min-width: $breakpoint-lg) {
          position: sticky;
          display: block;
          margin-left: 0;
        }
      }
    }
  }
}

// Mobile styles
.viewer__sidebar--is-open {
  top: 80px;

  .viewer__catalogue {
    @media screen and (width <= 1059px) and (orientation: landscape) {
      flex-direction: row;

      .vc-expanded {
        min-width: auto;
      }

      .viewer__catalogue--track {
        .viewer-button {
          &.viewer-button-down {
            margin-left: 110px;
          }
        }
      }
    }

    .viewer__catalogue--track {
      .viewer-button {
        &.viewer-button-down {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: 0;
  }
}
</style>
