/**
 * Calculates the live relative time between a given date and now.
 *
 * @param date - The date to compare against the current time.
 * @param currentTime - The current timestamp in milliseconds. Use `Date.now()` for real-time updates.
 * @param timezoneOffset - Optional timezone offset (e.g., "UTC", "America/New_York").
 * If provided, the current time will be adjusted to this timezone.
 * @returns A string representing the relative time, such as "2 hours 15 minutes ago" or "3 days 2 hours ago".
 */
export function liveRelativeTime(
  date: Date,
  currentTime: number,
  timezoneOffset?: string
): string {
  const now = timezoneOffset
    ? new Date(new Date(currentTime).toLocaleString('en-US', { timeZone: timezoneOffset }))
    : new Date(currentTime);

  const diffMs = now.getTime() - date.getTime();
  const seconds = Math.floor(diffMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
  if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
  if (days > 0) {
    const remainingHours = hours % 24;
    return `${days} day${days > 1 ? 's' : ''}${remainingHours > 0 ? ` and ${remainingHours} hour${remainingHours > 1 ? 's' : ''}` : ''} ago`;
  }
  if (hours > 0) {
    const remainingMinutes = minutes % 60;
    return `${hours} hour${hours > 1 ? 's' : ''}${remainingMinutes > 0 ? ` and ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}` : ''} ago`;
  }
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
}
