<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { dateTimeFormat } from '@utils/date';
import { useApplicationStore } from '@stores/application';
import { isClientResource, isProjectResource } from '@/utils/typeguards';
import * as client from '@gabrielcam/api-client';
import { ViewfinderCircleIcon } from '@heroicons/vue/24/solid';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { BadgeVariant, ButtonVariant, ImageSize, PageNames } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';


// Extended ViewCardProps to include hasCamera
interface ExtendedView extends client.WithExtendables_View_ {
  hasCamera: boolean;
}

// Card interface
interface ViewCardProps {
  resource: ExtendedView;
  loading: boolean;
  selected?: boolean;
}

// Default Props
const props = withDefaults(defineProps<ViewCardProps>(), {
  loading: true,
  selected: false,
});

// Refs
const scrollToSelectedCard = ref<HTMLDivElement>();

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

// Loading State from props
const isLoading = ref(props.loading);

// Permissions
const hasViewCameraAccess = applicationStore.canUser(client.Entitlements.UPDATE_VIEW, applicationStore.activeOrganisation!);

// Prop References
const viewStatus = ref<client.ViewStatus>(props.resource.status || client.ViewStatus.INACTIVE);
const viewIsPublic = ref(props.resource.isPublic);
const viewName = ref(props.resource.name);
const hasCamera = computed(() => props.resource.hasCamera);
const viewCameraId = computed(() => props.resource.camera);
const viewSelected = computed(() => props.selected);
const viewClientName = computed(() => isClientResource(props.resource.client) ? props.resource.client.name : null);
const viewProjectName = computed(() => isProjectResource(props.resource.project) ? props.resource.project.name : null);

const viewImageUrl = computed(() => props.resource.latestImageURL);
const viewHasImage = computed(() => !!viewImageUrl.value);
const viewImageLastCapture = computed(() =>
  props.resource.lastCapturedUtc
    ? dateTimeFormat(activeUser?.timezone).format(new Date(props.resource.lastCapturedUtc))
    : 'Never',
);

const fallbackMessage = computed(() => {
  const fallbackMessages = {
    [client.ViewStatus.INACTIVE]: 'This view is inactive and may not have any images captured',
    [client.ViewStatus.ARCHIVE]: 'This view is archived',
    [client.ViewStatus.ACTIVE]: 'This view is active',
  };
  return fallbackMessages[viewStatus.value] || '';
});

// Badge variant mapping
const badgeVariantMapping = {
  [client.ViewStatus.ACTIVE]: BadgeVariant.Success,
  [client.ViewStatus.INACTIVE]: BadgeVariant.Danger,
  [client.ViewStatus.ARCHIVE]: BadgeVariant.Warning,
};

// Compute Badge Variants
const badgeVariant = computed(() => badgeVariantMapping[viewStatus.value] || BadgeVariant.Danger);

onMounted(() => {
  scrollToCard();
});

watch(
  () => viewSelected.value,
  () => {
    scrollToCard();
  }
);

/**
 * Scroll to the element referenced by the ref `scrollToSelectedCard` if
 * `viewSelected` is true - this is used in the Map View Page to scroll to the selected card.
 */
function scrollToCard(): void {
  if (!viewSelected.value || !scrollToSelectedCard.value) return;

  scrollToSelectedCard.value.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
}
</script>

<template>
  <div ref="scrollToSelectedCard" class="view-card__container" :class="{ 'view-card__container--selected': viewSelected }">
    <!-- Show The Skeleton Card -->
    <div v-show="isLoading" class="view-card__container">
      <div class="view-card">
        <div class="view-card__status">
          <div class="skeleton-card skeleton-card__badge" />
        </div>

        <div class="view-card__header">
          <div class="skeleton-card skeleton-card__title" />
          <div class="skeleton-card skeleton-card__detail" />
          <div class="skeleton-card skeleton-card__detail" />
        </div>

        <div class="view-card__image">
          <div class="skeleton-card skeleton-card__image" />
        </div>

        <div class="view-card__details">
          <div class="skeleton-card skeleton-card__image-detail" />
        </div>

        <div class="view-card__actions">
          <div class="skeleton-card skeleton-card__button" />
          <div class="skeleton-card skeleton-card__button" />
          <div class="skeleton-card skeleton-card__button" />
          <div class="skeleton-card skeleton-card__button" />
        </div>
      </div>
    </div>

    <!-- Show The Real Card When Loaded -->
    <div v-show="!isLoading"
         :class="['view-card', {'view-card--selected': viewSelected }, `view-card--${viewStatus.toLowerCase()}`]">
      <div v-if="viewStatus" class="view-card__status">
        <BadgeComponent :variant="badgeVariant">
          {{ viewStatus }} VIEW
        </BadgeComponent>
        <BadgeComponent v-if="viewIsPublic" :variant="BadgeVariant.Warning">
          View is {{ viewIsPublic && 'PUBLIC' }}
        </BadgeComponent>
      </div>

      <div v-if="viewName" class="view-card__header">
        <Heading level="4" class="view-card__header--title">
          {{ viewName }}
        </Heading>
        <div v-if="viewProjectName || viewClientName" class="view-card__header--details">
          <div v-if="viewProjectName" class="text--truncate">
            <span class="text--bold">Project:</span> {{ viewProjectName }}
          </div>
          <div v-if="viewClientName" class="text--truncate">
            <span class="text--bold">Client:</span> {{ viewClientName }}
          </div>
        </div>
      </div>

      <div class="view-card__image">
        <!-- Show a fallback image/logo if viewImageUrl fails -->
        <template v-if="viewImageUrl">
          <router-link :to="{ name: PageNames.ViewViewer, params: { id: props.resource.id } }">
            <img :src="`${viewImageUrl}${ImageSize.Thumbnail}`"
                 loading="lazy"
                 aria-hidden="true"
                 :class="`view-card__image--${viewStatus.toLowerCase()}`"
                 :alt="`${viewName} view`">
            <ViewfinderCircleIcon class="view-finder-icon" />
          </router-link>
        </template>
        <template v-else>
          <p class="view-card__image--fallback">
            {{ fallbackMessage }}
          </p>
        </template>
      </div>

      <div class="view-card__content">
        <div class="text--truncate">
          <span class="text--bold">Last captured:</span> {{ viewImageLastCapture }}
        </div>
      </div>

      <div class="view-card__actions">
        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.MapPinIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="View on map"
                         :to="viewHasImage ? { name: PageNames.ViewMap, params: { viewId: props.resource.id } } : ''" />

        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.ViewfinderCircleIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Viewer"
                         :to="viewHasImage ? { name: PageNames.ViewViewer, params: { id: props.resource.id } } : ''" />
        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.PhotoIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Gallery"
                         :to="viewHasImage ? { name: PageNames.ViewGallery, params: { id: props.resource.id } } : ''" />
        <ButtonComponent :disabled="!viewHasImage"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.VideoCameraIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Videos"
                         :to="viewHasImage ? { name: PageNames.ViewVideos, params: { id: props.resource.id } } : ''" />
        <!-- Only show Camera Status Button if a view has a camera attached and the user has camera access -->
        <ButtonComponent v-if="hasViewCameraAccess && hasCamera"
                         :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.CameraIcon"
                         :icon-style="IconStyle.Outline"
                         :to="{ name: PageNames.CameraStatus, params: { id: viewCameraId } }" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.view-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: $gap-mobile;
  background-color: $neutral-100;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px $neutral-300;
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%
  }

  &--inactive {
    background-color: $red-100;
    box-shadow: inset 0 0 0 1px $red-300;
  }

  &--archive {
    background-color: $orange-50;
    box-shadow: inset 0 0 0 1px $orange-300;
  }

  &--selected {
    background-color: $green-200;
    box-shadow: inset 0 0 0 1px $green-800;
  }

  &__status {
    display: flex;
    column-gap: 10px;
    align-self: flex-end;
  }

  &__header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-block: 5px;
    margin-bottom: $margin-bottom;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-sm) {
        flex-basis: 2.4em;
      }
    }

    &--details {
      display: flex;
      flex-direction: column;
      font-size: .875rem;
      color: $neutral-600;
    }
  }

  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    // Use aspect ratio to stop CLS (Content Layout Shift)
    aspect-ratio: 16 / 9;
    margin-bottom: 5px;
    overflow: hidden;
    background: $neutral-200 url('/src/assets/background-light.png') repeat center center;
    background-size: 25%;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $neutral-300;

    & img {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      object-fit: cover;
      border-radius: 10px;
      transition: transform 200ms ease-in;
    }

    &:hover .view-finder-icon {
      opacity: 1;
      fill: $neutral-800;
    }

    & .view-finder-icon {
      position: absolute;
      inset: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
      fill: $neutral-50;
      width: 48px;
      height: 48px;
      transition: opacity 200ms ease-in, fill 200ms ease-in;
      pointer-events: none;
    }

    &--active {
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.7;
      }
    }

    &--archive {
      cursor: pointer;
      filter: grayscale(100%);
    }

    &--inactive {
      cursor: pointer;
      filter: grayscale(100%);
    }

    &--fallback {
      padding-inline: $gap-desktop;
      font-size: .875rem;
      color: $neutral-600;
      text-align: center;
    }
  }

  &__content {
    margin-bottom: $margin-bottom;
    font-size: .875rem;
    color: $neutral-600;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* Skeleton Loading Effect */
.skeleton-card {
  --initial-color: #f0f0f0;
  --final-color: #e0e0e0;

  background: linear-gradient(90deg, var(--initial-color), var(--final-color), var(--initial-color));
  background-size: 200% 100%;
  border-radius: 4px;
  animation: pulse 3000ms ease-in-out infinite;

  &__badge {
    width: 80px;
    height: 24px;
  }

  &__title {
    width: 200px;
    height: 24px;
  }

  &__detail {
    width: 100%;
    height: 16px;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  &__image-detail {
    width: 100%;
    height: 16px;
    margin-bottom: 20px;
  }

  &__button {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
}
</style>
