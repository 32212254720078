<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';

// Interface
interface FullscreenLayoutProps {
  metaTitle?: string;
}

// Props
const props = defineProps<FullscreenLayoutProps>();

// Refs
const metaTitle = ref(props.metaTitle || '');
const isVisible = ref(false); // Control visibility for transition
const router = useRouter();
const showOrgLogo = ref(true);

// Fade the layout using transition
onMounted(() => {
  isVisible.value = true;
});

/**
 * Handles the closure of the fullscreen layout.
 *
 * This function is called when the user closes the fullscreen layout.
 * It determines the previous route and navigates to it, or to the views page if the previous route was the login page.
 *
 * @returns {void}
 */
const closeFullscreen = (): void => {
  // Get the previous route
  const previousRoute = router.options.history.state['back'];

  // Hide the fullscreen layout
  isVisible.value = false;

  // Wait for the fade animation to complete before navigating to the next route
  setTimeout(() => {
    let previousRoutePath: string | null = null;

    if (typeof previousRoute === 'string') {
      try {
        // Parse the previous route path safely
        previousRoutePath = new URL(previousRoute, window.location.origin).pathname;
      } catch {
        // Handle invalid URLs gracefully
        console.error('Invalid URL in previousRoute:', previousRoute);
      }
    }

    // Mitigate the issue of the previous route being the login page where we get stuck in a loop, send them to views if the previous route is the login page
    if (previousRoutePath && previousRoutePath.includes(`/${PageNames.Login}`)) {
      router.push(`/${PageNames.Views}`);
    } else if (previousRoutePath) {
      router.back();
    } else {
      router.push(`/${PageNames.Views}`);
    }
  }, 300); // Match the duration of the `fade` animation
};
</script>

<template>
  <Transition name="fade">
    <div v-if="isVisible" class="fullscreen-layout">
      <header class="fullscreen-layout--header">
        <div v-if="showOrgLogo" class="fullscreen-layout--header-logo" />
        <Heading v-else level="2" class="text--truncate">
          {{ metaTitle.length > 28 ? metaTitle.slice(0, 28) + '...' : metaTitle }}
        </Heading>
        <ButtonComponent aria-label="Close modal"
                         :icon-name="IconName.XMarkIcon"
                         :icon-style="IconStyle.Outline"
                         :variant="ButtonVariant.Dark"
                         :is-icon-btn="true"
                         @click="closeFullscreen" />
      </header>
      <main class="fullscreen-layout--body">
        <slot name="router-view" />
      </main>
      <footer class="fullscreen-layout--footer">
        <slot name="footer" />
      </footer>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.fullscreen-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: $neutral-900;

  &--header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 20px 15px 20px clamp(15px, 3vw, 30px);
    color: $neutral-50;
    background-color: var(--primary-color, $primary-color);

    &-logo {
      z-index: 16;
      display: block;
      width: calc(100% - 30px);
      height: 50px;
      background-image: var(--logo, $logo);
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;
    }
  }

  &--body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 20vh;
    max-height: 100vh; // fallback as svh is not supported by Safari
    max-height: 100svh;
    padding-inline: clamp(15px, 3vw, 30px);
    padding-block: 30px;
    overflow: auto;
    background-color: $neutral-50;
    color: $neutral-800;
    overflow-x: hidden; // stop left and right scrolling
  }

  &--footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    padding-inline: clamp(15px, 3vw, 30px);
    color: $neutral-50;
    background-color: var(--primary-color, $primary-color);

    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.fade-enter-active {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-leave-active {
  animation: fadeOut 0.2s ease-out forwards;
}
</style>
