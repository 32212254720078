<script setup lang="ts">
import { computed } from 'vue';
import { CardContainerLayout, PageNames } from '@viewModels/enums';
import { useRoute } from 'vue-router';

const routeLayoutMapping: Record<string, CardContainerLayout> = {
  [PageNames.View]: CardContainerLayout.Default,
  [PageNames.ViewMap]: CardContainerLayout.GoogleMap,
  [PageNames.ViewDownloads]: CardContainerLayout.Downloads,
};

const route = useRoute();
const currentLayout = computed(() =>
  routeLayoutMapping[route.name as keyof typeof PageNames] || CardContainerLayout.Default
);

const layoutClassMapping: Record<CardContainerLayout, string> = {
  [CardContainerLayout.Default]: '',
  [CardContainerLayout.GoogleMap]: 'card-container__google-map',
  [CardContainerLayout.Downloads]: 'card-container__downloads',
};

const containerClass = computed(() => layoutClassMapping[currentLayout.value]);
</script>

<template>
  <section class="card-container" :class="containerClass">
    <slot />
  </section>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: clamp($gap-desktop, 2vw, $gap-default * 2);
  justify-items: center;

  &__downloads {
    display: flex;
    flex-direction: column;
    order: 1;
    margin-bottom: $margin-bottom;
  }

  &__google-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    order: 2;

    @media screen and (width >= $breakpoint-xl) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      order: 1;
      margin-bottom: unset;
    }
  }
}


</style>
