<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import { useCurrentRoute } from '@utils/getCurrentRoute';
import { storeToRefs } from 'pinia';
import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import {
  AlertVariant,
  BreadcrumbPaths,
  BreadcrumbTitles,
  ButtonType,
  ButtonVariant,
  PageNames,
} from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import AlertBanner from '@components/AlertBanner.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import ViewSourceForm from '@components/view/ViewSourceForm.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ViewOriginForm from '@components/view/ViewOriginForm.vue';
import ViewSourceImportForm from '@components/view/ViewSourceImportForm.vue';

interface ViewUpdateForm {
  name: string;
  camera?: string;
  client: string;
  project: string;
  longitude: number;
  latitude: number;
  isPublic: boolean;

  bucket?: string;
  prefix?: string;
}

const schema = yup.object({
  name: yup.string().required(),
  camera: yup.string().nullable(),
  client: yup.string().nullable(),
  project: yup.string().nullable(),
  longitude: yup.number().required(),
  latitude: yup.number().required(),
  isPublic: yup.boolean().required(),
});

const { handleSubmit } = useForm<ViewUpdateForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: cameraValue, errorMessage: cameraError } = useField<string | undefined>('camera', 'camera');
const { value: clientValue, errorMessage: clientError } = useField<string>('client', 'client');
const { value: projectValue, errorMessage: projectError } = useField<string>('project', 'project');
const { value: longitudeValue, errorMessage: longitudeError } = useField<number>('longitude', 'longitude');
const { value: latitudeValue, errorMessage: latitudeError } = useField<number>('latitude', 'latitude');
const { value: isPublicValue } = useField<boolean>('isPublic', 'isPublic');

const route = useRoute();
const { currentRouteMetaTitle } = useCurrentRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

const viewId = route.params['id'] as string;
const isCameraLive = ref<boolean>(false);

const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(false);

const currentView = ref<View>();
const cameras = ref<client.Camera[]>([]);
const filteredCameras = ref<client.Camera[]>([]);
const clients = ref<client.Client[]>([]);
const filteredClients = ref<client.Client[]>([]);
const alertMessage = ref<string>('');
const alertVariant = ref<AlertVariant>(AlertVariant.Light);

const projects = ref<client.Project[]>([]);
const filteredProjects = ref<client.Project[]>([]);

const breadcrumbs = ref<BreadCrumbItem[]>([]);
const breadcrumbKey = ref(0);

const publicUrl = ref<string | null>(null);
const basePublicUrl = `${window.location.origin}/views/public/`;

// Handle form submission
const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;

  const request: client.UpdateViewRequest = {
    name: values.name,
    camera: values.camera || undefined,
    client: values.client || undefined,
    project: values.project || undefined,
    longitude: values.longitude,
    latitude: values.latitude,
    isPublic: values.isPublic,
  };

  const response = await viewStore.updateView(viewId, request);

  isSubmitting.value = false;

  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    return;
  }

  // Update the public URL if the view is public
  if (values.isPublic) {
    publicUrl.value = `${basePublicUrl}${viewId}`;
  } else {
    publicUrl.value = null;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully updated view.',
    autoCloseMs: 3000,
  });
});

const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
  nameValue.value = currentView.value.name;
  cameraValue.value = currentView.value.camera;
  clientValue.value = currentView.value.client!;
  projectValue.value = currentView.value.project!;
  longitudeValue.value = currentView.value.longitude ?? 0;
  latitudeValue.value = currentView.value.latitude ?? 0;
  isPublicValue.value = currentView.value.isPublic ?? false;
  cameraValue.value = currentView.value.camera; // Save camera ID for fetching live stats

  // Set the publicUrl after fetching data
  if (isPublicValue.value) {
    publicUrl.value = `${basePublicUrl}${viewId}`;
  } else {
    publicUrl.value = null;
  }

  try {
    // Fetch live camera stats using the cameraId
    const cameraStats = await client.getCameraByIdStatLatest({
      cameraId: cameraValue.value,
    } as client.GetCameraByIdStatLatestData);

    // Check if the view has live camera stats
    isCameraLive.value = !!cameraStats;

    // Check if cameraStats contains valid live latitude and longitude
    if (cameraStats && cameraStats.gnssLatitude != null && cameraStats.gnssLongitude != null) {
      const liveLatitude = cameraStats.gnssLatitude / 1000000;
      const liveLongitude = cameraStats.gnssLongitude / 1000000;

      // Set dynamic alert to inform the user that manual input will override the live data
      alertVariant.value = AlertVariant.Light;
      alertMessage.value = `We have live data for the latitude (${liveLatitude}) and longitude (${liveLongitude}) for this Camera.
      By using the manual input below, you will overrule the live location on the Map view.`;
    } else {
      // No valid live data or error occurred
      alertVariant.value = AlertVariant.Warning;
      alertMessage.value = `There is no live latitude or longitude coordinates available right now. The manual data below will be used instead.`;
    }
  } catch (error) {
    console.error(`Failed to fetch live stats for camera ${cameraValue.value}`, error);
    // Fallback error message is the same as "no valid live data"
    alertVariant.value = AlertVariant.Warning;
    alertMessage.value = `There is no live latitude or longitude coordinates available right now. The manual data below will be used instead.`;
  }
};

onMounted(async () => {
  await fetchViewData();

  // Update breadcrumbs
  updateBreadcrumbs();

  cameras.value = (await client.listCameras({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredCameras.value = [...cameras.value];

  clients.value = (await client.listClients({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredClients.value = [...clients.value];

  projects.value = (await client.listProjects({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredProjects.value = [...projects.value];

  isLoading.value = false;
});

const pageHeading = computed(() => {
  return currentView.value?.name + ' ' + currentRouteMetaTitle.value;
});

function updateBreadcrumbs(): void {
  if (currentView.value?.name) {
    breadcrumbs.value = [
      { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews  },
      { title: `${nameValue.value}`, active: false },
    ];
    breadcrumbKey.value += 1; // HACK: Increment key to force re-render
  } else {
    breadcrumbs.value = [];
  }
}
</script>

<template>
  <SubHeader :heading="isLoading ? 'Loading...' : pageHeading"
             level="2" />

  <Breadcrumb :key="breadcrumbKey"
              :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Loading v-if="isLoading" />

    <form v-if="!isLoading"
          @submit.prevent="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            View Information
          </Heading>
          <p>Update a view.</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="view-name">Name</label>
              <input id="view-name"
                     v-model="nameValue"
                     type="text">
              <p class="message message-error">
                {{ nameError }}
              </p>
            </div>
            <div class="field" />
          </div>

          <div class="field">
            <label for="model">Client</label>
            <v-select v-model="clientValue"
                      :options="filteredClients"
                      :reduce="(client: client.Client) => client.id"
                      label="name"
                      placeholder="Please select (optional)"
                      @search="
                        (search: string) => {
                          filteredProjects = clients.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <p class="message message-error">
              {{ clientError }}
            </p>
          </div>

          <div class="field">
            <label for="model">Project</label>
            <v-select v-model="projectValue"
                      :options="filteredProjects"
                      :reduce="(project: client.Project) => project.id"
                      label="name"
                      placeholder="Please select (optional)"
                      @search="
                        (search: string) => {
                          filteredProjects = projects.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <p class="message message-error">
              {{ projectError }}
            </p>
          </div>

          <div class="field">
            <label for="model">Camera</label>
            <v-select v-model="cameraValue"
                      :options="filteredCameras"
                      :reduce="(camera: client.Camera) => camera.id"
                      label="serialNumber"
                      placeholder="Please select (optional)"
                      @search="
                        (search: string) => {
                          filteredCameras = cameras.filter((x) => {
                            if (!!search.length) return true;
                            return x.serialNumber.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
          </div>

          <div class="d-flex justify-end mb-20">
            <ButtonComponent v-if="applicationStore.adminMode && isCameraLive"
                             :variant="ButtonVariant.Dark"
                             :to="{ name: PageNames.CameraStatus, params: { id: cameraValue } }"
                             :is-block-btn="true">
              Camera Status
            </ButtonComponent>

            <p class="message message-error">
              {{ cameraError }}
            </p>
          </div>

          <AlertBanner v-if="cameraValue"
                       :variant="alertVariant as AlertVariant"
                       :has-bottom-margin="true"
                       :icon-name="IconName.InformationCircleIcon"
                       :icon-style="IconStyle.Outline">
            <template #mainContent>
              <span class="text--size-5">{{ alertMessage }}</span>
            </template>
          </AlertBanner>

          <div class="row-half">
            <div class="field">
              <label for="model">Latitude</label>
              <input id="view-name"
                     v-model="latitudeValue"
                     type="text">
              <p class="message message-error">
                {{ latitudeError }}
              </p>
            </div>
            <div class="field">
              <label for="model">Longitude</label>
              <input id="view-name"
                     v-model="longitudeValue"
                     type="text">
              <p class="message message-error">
                {{ longitudeError }}
              </p>
            </div>
          </div>

          <div class="checkbox-field">
            <label class="checkbox-label"
                   for="isPublic">
              {{ publicUrl ? 'Publicly accessible URL' : 'Create publicly accessible URL' }}
              <span v-show="publicUrl" class="word-wrap">
                <a v-if="publicUrl"
                   :href="publicUrl"
                   class="break-word"
                   target="_blank">{{ publicUrl }}</a>
              </span>
            </label>
            <input id="isPublic"
                   v-model="isPublicValue"
                   type="checkbox">
          </div>
        </div>
      </div>

      <ButtonContainer>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :loading="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Update
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>

  <ViewSourceForm v-if="!isLoading && adminMode" />
  <ViewSourceImportForm v-if="!isLoading && adminMode" />
  <ViewOriginForm v-if="!isLoading && adminMode" />
</template>

<style lang="scss" scoped>
.checkbox-field {
  & .checkbox-label {
    margin-left: 10px;
    font-weight: normal;

    & .word-wrap {
      display: inline-block;
      width: 100%;
    }

    & .break-word {
      word-break: break-all;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  & input[type='checkbox'] {
    flex-shrink: 0;
  }
}
</style>
