<script lang="ts" setup>
import { PageNames } from '@viewModels/enums';

const { pageName } = defineProps<{
  pageName: string;
}>();

// Do not show the Sidebar Nav on the ViewPublic page
const showSidebar = pageName !== PageNames.ViewPublic;
</script>

<template>
  <aside v-if="showSidebar" class="app--sidebar">
    <slot name="navbar" />
  </aside>
</template>

<style lang="scss">
@use '@scss/variables' as *;

.app--sidebar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 80px;
  background-color: var(--primary-color, $primary-color);

  @media screen and (min-width: $breakpoint-lg) {
    z-index: 15;
    flex-direction: column;
    width: 230px;
    height: 100%;
    transition: width 0.15s ease;
  }

  header {
    z-index: 16;
    width: 100%;
    background-color: var(--primary-color, $primary-color);

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }

    a {
      &:link,
      &:visited {
        display: block;
        height: 50px;
        margin: 15px;
        text-indent: -3000em;
        background-image: var(--logo, $logo);
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        opacity: 0.8;
      }
    }
  }

  .sidebar-container {
    position: fixed;
    bottom: 0;
    z-index: 15;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 16;
      width: 100%;
      height: 100%;
      content: '';
      background-color: var(--primary-color, $primary-color);
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .sidebar-container {
      flex-direction: column;
      justify-content: start;
      width: 230px;
      height: 100vh;
      transition: width 0.15s ease;

      &::before {
        z-index: -1;
        box-shadow: inset -1px 0 0 $white-opacity-25;
      }
    }
  }

  &-logo {
    display: none;

    @media screen and (min-width: $breakpoint-lg) {
      z-index: 16;
      display: block;
      width: calc(100% - 30px);
      height: 70px;
      margin: 30px auto;
      background-image: var(--logo, $logo);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .menu-toggle {
    z-index: 17;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    padding: 0;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }

    svg {
      width: 40px;
      height: 40px;
      padding: 2px;
      fill: $neutral-200;
    }
  }

  .menu-container {
    position: absolute;
    bottom: -9999px;
    left: 0;
    z-index: 15;
    width: 100%;
    visibility: hidden;
    background-color: var(--primary-color, $primary-color);
    transition:
      top 0.15s ease,
      visibility 0.15s;

    @media screen and (min-width: $breakpoint-lg) {
      position: relative;
      top: 0;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      width: inherit;
      overflow: hidden auto;
      visibility: visible;
      background-color: transparent !important;
    }
  }

  hr {
    margin: 5px 1px;
    color: $neutral-400;
    border: none;
    border-bottom: 1px solid $white-opacity-25;
  }

  .menu {
    z-index: 16;
    display: flex;
    flex-direction: column;
    gap: 8px 5px;

    @media screen and (min-width: $breakpoint-lg) {
      align-items: stretch;
    }

    &-organisation {
      padding: 5px 0;
      margin-bottom: 10px;
      overflow: hidden;
      color: $neutral-400;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid $white-opacity-25;

      &-select:not(.vs--open.menu-organisation-select) {
        width: 100% !important;
        margin: 0 auto $margin-bottom;
        color: inherit !important;
        background-color: $black-opacity-25;
        // border-color: $white-opacity-25;
        // border-radius: 5px;
        // outline: 1px solid $white-opacity-25;
      }
    }

    & .vs--open.menu-organisation-select {
      width: 100%;
      margin: 0;
      color: inherit;
    }

    svg.menu-icon {
      width: 20px;
      height: 20px;
    }

    svg.arrow-icon {
      width: 20px;
      height: 20px;
      margin-left: auto;
      transition: transform 0.3s ease;
    }

    svg.rotate-180 {
      transform: rotate(180deg);
    }

    li,
    div.menu-footer {
      list-style: none;

      @media screen and (min-width: $breakpoint-lg) {
        display: block;
      }

      .menu-dropdown {
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-height: 36px;
        padding: 10px;
        margin: auto;
        overflow: hidden;
        color: $neutral-200;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        outline: 1px solid transparent;
        transition: all 300ms linear;

        &:hover,
        &:focus,
        &:active {
          cursor: pointer;
          background-color: $black-opacity-25;
          outline: 1px solid $white-opacity-25;
        }

        &.has-submenu {
          margin-bottom: -6px;
          cursor: default;
          background-color: unset;
        }

        .menu-header-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon-container {
          margin-left: auto;
        }
      }

      &.menu-header {
        padding: 5px 0;
        margin-bottom: 15px;
        color: $neutral-400;
        border-bottom: 1px solid $white-opacity-25;

        @media screen and (min-width: $breakpoint-lg) {
          display: block;
          padding-left: 34px;
          margin: 10px 0 15px -17px !important;
          font-size: 0.85em;
          color: $neutral-200;
          opacity: 0.5;

          a {
            &:link,
            &:visited {
              padding: 0;
              margin: 0;
              pointer-events: none;
            }
          }

          @media screen and (height <= 600px) {
            height: 1px;
            margin: 4px 0 6px;
            text-indent: -3000px;
            background: $white-opacity-50;
          }
        }
      }

      a {
        &:link,
        &:visited {
          display: flex;
          column-gap: 8px;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 10px;
          overflow: hidden;
          color: $neutral-200;
          text-decoration: none;
          white-space: nowrap;
          border-radius: 5px;
          outline: 1px solid transparent;
          transition: all 300ms linear;

          @media screen and (min-width: $breakpoint-lg) {
            @media screen and (height <= 700px) {
              padding: 8px 46px 8px 42px;
            }

            @media screen and (height <= 640px) {
              padding: 6px 48px 6px 40px;
            }
          }
        }

        &:active,
        &:hover,
        &:focus,
        &:focus-visible,
        &.router-link-exact-active {
          background-color: $black-opacity-25;
          outline: 1px solid transparent;
          box-shadow: inset 0 0 0 1px $white-opacity-25;
        }

        &.router-link-exact-active {
          pointer-events: none;
        }
      }

      .submenu {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding: 8px 2px 8px 27px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &.menu-primary {
      padding: 30px 20px 0;
      line-height: 1;
      list-style: none;

      @media screen and (min-width: $breakpoint-lg) {
        padding: 0 20px;
      }
    }

    &.menu-secondary {
      position: relative;
      z-index: 15;
      padding: 10px 25px;
      margin-top: 30px;
      background-color: $black-opacity-25;

      @media screen and (min-width: $breakpoint-lg) {
        padding: 20px 15px;
      }

      .menu-item {
        display: flex;
        column-gap: 8px;
        align-items: center;
      }

      .menu-footer a {
        margin: 0 !important;
      }

      svg.footer-icon {
        width: 24px;
        height: 24px;
      }

      li a:hover,
      li a:focus,
      li a:focus-visible {
        background-color: $white-opacity-25;
      }
    }
  }
}

.app-container.mobile-nav-open {
  .app--sidebar {
    .menu-container {
      @media screen and (max-width: $breakpoint-lg) {
        bottom: 80px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        visibility: visible;
        border-radius: 15px 15px 0 0;
        transition:
          bottom 200ms ease,
          visibility 0.15s ease;
      }
    }
  }
}
</style>
