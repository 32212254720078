<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import * as yup from 'yup';
import { useApplicationStore } from '@stores/application';
import { useImageStore } from '@stores/image';
import { useMobileDetection } from '@utils/isMobile';
import { ButtonVariant, ImageSize, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import GalleryDatePicker from '@components/gallery/GalleryDatePicker.vue';
import GalleryDirectoryPagination from '@components/gallery/GalleryDirectoryPagination.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import EmptyState from '@layouts/EmptyState.vue';
import Heading from '@components/Heading.vue';
import GalleryCard from '@components/cards/GalleryCard.vue';
import HorizontalRule from '@components/HorizontalRule.vue';

const route = useRoute();
const imageStore = useImageStore();
const applicationStore = useApplicationStore();
const { isMobile } = useMobileDetection();
const { images, allImages } = storeToRefs(imageStore);

const viewId = route.params['id'] as string;
const availableDates = ref<string[]>([]);

const isLoading = ref<boolean>(true);
const downloading = ref<boolean>(false);

let startOfDay = dayjs().startOf('day');
let endOfDay = dayjs().endOf('day');

const startDate = ref<Date>(startOfDay.toDate());
const endDate = ref<Date>(endOfDay.toDate());

const isImageModalVisible = ref<boolean>(false);
const isLoadingImage = ref<boolean>(true);
const selectedImage = ref<client.Image | undefined>(undefined);

const validationError = ref<string | null>(null);

// Define the DatePicker Schema
const schema = yup.object({
  startDate: yup.date().required('Start date is required'),
  endDate: yup
    .date()
    .required('End date is required')
    .min(yup.ref('startDate'), 'End date cannot be earlier than the start date'),
});

const openImageModal = (image: typeof selectedImage.value): void => {
  if (!image) return;

  selectedImage.value = image;
  isLoadingImage.value = true;
  isImageModalVisible.value = true;

  const img = new Image();
  // Get the image source depending on the screen size
  img.src = image.sourceURL + (isMobile ? ImageSize.Medium : ImageSize.Large) as string;
  img.onload = onImageLoad;
  img.onerror = onImageError;
};

const closeImageModal = (): void => {
  selectedImage.value = undefined;
  isImageModalVisible.value = false;
};

const onImageLoad = (): void => {
  isLoadingImage.value = false;
};

const onImageError = (): void => {
  isLoadingImage.value = false;
};

/**
 * Downloads a single image by constructing the download URL from imageThumbnailUrl and imageFileName.
 *
 * @returns {void} This function does not return anything.
 */
const downloadSingleImage = (): void => {
  // Construct the download URL
  const url = `${selectedImage.value}?dl=${selectedImage.value?.originalFileName}`;

  // Trigger the download
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.setAttribute('download', selectedImage.value?.originalFileName || '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  applicationStore.publishSuccessNotification({
    text: 'Image downloading, please check your browser’s downloads folder',
    autoCloseMs: 3000,
  });
};

/**
 * Searches for images by view and selected date range.
 *
 * This function validates the selected dates, clears any validation errors,
 * and then searches for images within the specified date range.
 *
 * @returns {Promise<void>} A promise that resolves when the search is complete.
 */
async function searchImages(): Promise<void> {
  try {
    // Validate the selected dates
    await schema.validate({ startDate: startDate.value, endDate: endDate.value });

    // Clear any validation errors
    validationError.value = null;

    // Search for images by view and selected date range
    isLoading.value = true;
    await imageStore.obtainImagesByDateRange(viewId, startDate.value, endDate.value);

    // Update the validation error message on the UI
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      validationError.value = error.message;
      console.error('Validation error:', error.message);
    } else {
      console.error('An error occurred during image search:', error);
    }
  } finally {
    isLoading.value = false;
  }
}

async function downloadImages(): Promise<void> {
  try {
    downloading.value = true;
    const fileNames = allImages.value.filter((x) => x.originalFileName != undefined).map((x) => x.originalFileName!);
    await client.createBatchDownload({
      requestBody: { files: fileNames, organisation: applicationStore.activeOrganisation!.id, view: viewId },
    });
    applicationStore.publishSuccessNotification({
      text: 'Creating batch, please check the downloads page later.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    applicationStore.publishErrorNotification({
      text: 'An internal error occurred.',
      autoCloseMs: 3000,
    });
    console.error(error);
  } finally {
    downloading.value = false;
  }
}

async function deleteImage(id: string): Promise<void> {
  try {
    await imageStore.deleteImage(id);
    applicationStore.publishSuccessNotification({
      text: 'Image successfully deleted.',
      autoCloseMs: 3000,
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(error.message);
      applicationStore.publishErrorNotification({
        text: 'An error occurred while deleting the image.',
      });
    } else {
      // Handle unknown error types
      console.error(error);
      applicationStore.publishErrorNotification({
        text: 'An unknown error occurred while trying to delete the image.',
      });
    }
  } finally {
    // Respect updated startDate and endDate
    if (startDate.value && endDate.value) {
      await searchImages(); // Ensure searchImages uses the current date range
    } else {
      console.warn('No valid date range selected. Skipping image refresh.');
    }
  }
}

onMounted(async () => {
  availableDates.value = (await client.listViewByIdCapturedDates({ viewId })).data;

  if (availableDates.value.length != 0) {
    startDate.value = new Date(availableDates.value.at(-1)!);
    endDate.value = new Date(availableDates.value.at(-1)!);
  }
  await searchImages();
});
</script>

<template>
  <ContainerCard>
    <Heading level="4" :has-bottom-margin="true">
      Filter Images by Date Range
    </Heading>

    <div class="mb-20">
      Date selected:
      <span class="text--number">
        <template v-if="dayjs(startDate).isSame(dayjs(endDate), 'day')">
          {{ dayjs(startDate).format('DD/MM/YYYY') }}
        </template>
        <template v-else>
          {{ dayjs(startDate).format('DD/MM/YYYY') }} - {{ dayjs(endDate).format('DD/MM/YYYY') }}
        </template>
      </span>
    </div>

    <form>
      <div class="field-group">
        <div class="fields fields-datepickers">
          <!-- Start Date Picker -->
          <div class="field">
            <label for="start-date" class="sr-only" aria-live="polite">Select a Start Date</label>
            <GalleryDatePicker id="start-date"
                               v-model="startDate"
                               :available-dates="availableDates"
                               @update:model-value="(e: Date) => { startDate = e; }"
                               @date-selected="searchImages" />
          </div>
          <!-- End Date Picker -->
          <div class="field">
            <label for="end-date" class="sr-only" aria-live="polite">Select an End Date</label>
            <GalleryDatePicker id="end-date"
                               v-model="endDate"
                               :available-dates="availableDates"
                               @update:model-value="(e: Date) => { endDate = e; }"
                               @date-selected="searchImages" />
            <p v-if="validationError" class="message message-error">
              {{ validationError }}
            </p>
          </div>
        </div>
      </div>
    </form>

    <ButtonContainer class="flex-grow-1">
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :disabled="downloading"
                       @click="downloadImages">
        Create Batch Download
      </ButtonComponent>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       :disabled="downloading"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewDownloads, params: { id: viewId } }">
        View Batch Downloads
      </ButtonComponent>
    </ButtonContainer>
  </ContainerCard>

  <ContainerCard>
    <GalleryDirectoryPagination />

    <HorizontalRule />

    <section class="gallery">
      <Directory :object-collection-reference="images.data"
                 :loading="isLoading">
        <template #default="scope">
          <GalleryCard :resource="scope.resource"
                       @show-image-modal="openImageModal(scope.resource)"
                       @on-delete="(image: client.Image) => deleteImage(image.id)" />
        </template>

        <template #table-empty>
          <EmptyState heading-text="No images found"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </Directory>

      <HorizontalRule />

      <GalleryDirectoryPagination />
    </section>
  </ContainerCard>

  <!-- Image Modal -->
  <ModalComponent :visible="isImageModalVisible"
                  :heading-title="`View ${selectedImage?.originalFileName}`"
                  :is-large="true"
                  @on-close="closeImageModal">
    <template #modal-content>
      <Loading v-if="isLoadingImage"
               :is-absolute-positioned="true" />
      <div v-else>
        <img v-if="selectedImage?.sourceURL"
             class="modal-image"
             :src="`${selectedImage.sourceURL}${isMobile ? ImageSize.Medium : ImageSize.Large}`"
             alt="Image preview"
             :aria-hidden="true"
             @load="onImageLoad"
             @error="onImageError">
      </div>
    </template>
    <template #modal-footer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       @click="closeImageModal">
        Close
      </ButtonComponent>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :icon-name="IconName.ArrowDownTrayIcon"
                       :icon-style="IconStyle.Outline"
                       :disabled="isLoadingImage || !selectedImage?.sourceURL"
                       @click="downloadSingleImage">
        Download Image
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
:deep(.modal__content--body) {
  aspect-ratio: 6 / 4;
  margin: auto;
}

.modal-image {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: calc(60vh - 40px);
  aspect-ratio: 6 / 4;
  margin: auto;
}
</style>
