<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { BreadcrumbTitles, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableColumn } from '@components/table/models/Table';
import TLSTable from '@components/table/TLSTable.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import ModalComponent from '@components/ModalComponent.vue';

const enum TableHeaders {
  Name = 'Name',
  Actions = 'Actions',
}

const applicationStore = useApplicationStore();
const showNew = applicationStore.canUser(client.Entitlements.CREATE_PROJECT, applicationStore.activeOrganisation!);
const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_PROJECT, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_PROJECT, applicationStore.activeOrganisation!);

const projectList = ref<client.ListProjectsResponse | null>(null);
const projectName = ref('');
const isLoading = ref<boolean>(true);
const showDeleteProjectModel = ref(false);
const deleting = ref(false);
const deleteProjectModelFunc = ref<Function>(() => deleteProject);

/**
 * Retrieves a list of projects for the active organisation.
 *
 * @returns {Promise<client.ListProjectsResponse | null>} A promise that resolves to the project list response object or null on error.
 */
async function getProjectList(): Promise<client.ListProjectsResponse | null> {
  try {
    const org = applicationStore.activeOrganisation;
    const res = await client.listProjects({ organisation: org!.id });
    projectList.value = res;
    return res;
  } catch (error) {
    console.error("Error fetching project list:", error);
    return null;
  }
}

onMounted(async () => {
  isLoading.value = true;
  projectList.value = await getProjectList();
  isLoading.value = false;
});

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    headerClasses: 'status',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteProjectModel.value = false;
  deleting.value = false;
}

async function showDeleteConfirmModal(row: client.Project): Promise<void> {
  showDeleteProjectModel.value = true;
  projectName.value = row.name;

  deleteProjectModelFunc.value = async (): Promise<void> => {
    await deleteProject(row);
  };
}

async function deleteProject(row: client.Project): Promise<void> {
  deleting.value = true;
  try {
    await client.deleteProjectById({ projectId: row.id });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
      return;
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    deleting.value = false;
  }

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted project.',
    autoCloseMs: 3000,
  });

  // Refresh the table
  isLoading.value = true;
  await getProjectList();
  isLoading.value = false;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllProjects, active: true },
];
</script>

<template>
  <SubHeader :heading="BreadcrumbTitles.AllProjects"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ProjectCreate }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add Project
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Loading v-if="isLoading" />

    <template v-else>
      <TLSTable :columns="table.columns"
                :data="projectList?.data || []"
                :is-loading="isLoading"
                :total="table.totalRecordCount"
                :sortable="table.sortable">
        <template #table-empty>
          <EmptyState heading-text="No projects found"
                      strap-line="Get started by naming your first project"
                      :button-variant="ButtonVariant.Dark"
                      button-text="New Project"
                      :icon-name="IconName.ClipboardDocumentListIcon"
                      :icon-style="IconStyle.Outline"
                      :to="{ name: PageNames.ProjectCreate }" />
        </template>

        <template #cell="{ row, column }">
          <template v-if="column.label === TableHeaders.Actions">
            <div class="d-flex gap-20">
              <ButtonComponent v-if="showUpdate"
                               :to="{ name: PageNames.ProjectEdit, params: { id: row.id } }"
                               :size="ButtonSize.Small"
                               :variant="ButtonVariant.Dark">
                Edit
              </ButtonComponent>
              <ButtonComponent v-if="showDelete"
                               :size="ButtonSize.Small"
                               :variant="ButtonVariant.Danger"
                               @click="showDeleteConfirmModal(row)">
                Delete
              </ButtonComponent>
            </div>
          </template>
        </template>
      </TLSTable>
    </template>
  </ContainerCard>

  <!-- Delete Client Modal -->
  <ModalComponent :visible="showDeleteProjectModel"
                  heading-title="Delete Project"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--bold">{{ projectName }}</span> project?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Button"
                       :is-block-btn="true"
                       :loading="deleting"
                       :disabled="deleting"
                       :variant="ButtonVariant.Danger"
                       @click="deleteProjectModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
:deep(.table-wrapper) {
  margin-bottom: unset;
}
</style>
