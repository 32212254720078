<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import ModalComponent from '@components/ModalComponent.vue';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import { CheckIcon } from '@heroicons/vue/24/solid';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { sleep } from '@utils/sleep';
import { CreateCameraTokenResponse } from '@gabrielcam/api-client';

const PAUSE_UI = 1000; // Pause duration before initial probe
const PROBE_INTERVAL = 2000; // Interval between retries
const WAIT_FOR_PI_UI = 3000; // Buffer time after 200 OK to ensure UI readiness

enum States {
  PROBING = 'PROBING',
  SENDING_BOOT = 'SENDING_BOOT',
  AWAITING_BOOT = 'AWAITING_BOOT',
  LAUNCHING = 'LAUNCHING',
  ERROR = 'ERROR',
}

enum StateMessage {
  PROBING = 'Initialising camera',
  SENDING_BOOT = 'Sending boot command',
  AWAITING_BOOT = 'Awaiting boot',
  LAUNCHING = 'Launching configurator',
}

enum ProgressIcon {
  IN_PROGRESS = 'progress-icon progress-icon--in-progress',
  PENDING = 'progress-icon progress-icon--pending',
  COMPLETE = 'progress-icon progress-icon--complete',
  FAILED = 'progress-icon progress-icon--failed',
}

const completedStates = ref<States[]>([]);
const currentState = ref<States | null>();
const launchErrorMessage = ref<string>();
const apiErrorMessage = ref<string>();
const tokenInformation = ref<CreateCameraTokenResponse>();
const pollingInterval = ref();

const props = defineProps({
  camera: { type: Object as PropType<client.Camera>, required: true },
  onClose: { type: Function, required: true}
});

/**
 * Initialises the connection to a remote camera device and manages the state of its boot sequence.
 *
 * This function performs the following tasks:
 * 1. Requests an authentication token for the camera device via the API.
 * 2. Sets a local authentication URL if running on localhost, enabling local testing.
 * 3. Probes the device URL to check if the device is accessible and responsive.
 * 4. If the device is available, simulates the boot sequence steps and launches the device.
 * 5. If the device is not available, sends a boot command and continues to probe the device
 *    at regular intervals until it becomes accessible, at which point it launches the device.
 *
 * State and UI Feedback:
 * - Updates UI states (`completedStates`, `currentState`) to reflect the progress of each step.
 * - Implements a polling mechanism to repeatedly check device availability when in the boot sequence.
 *
 * @returns {Promise<void>} Resolves when the device has been successfully launched or when
 *                          initialisation has completed without further required actions.
 */
const init = async (): Promise<void> => {
  try {
    // Step 1: Get device details
    tokenInformation.value = await client.createCameraToken({ cameraId: props.camera.id });

    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      tokenInformation.value.deviceUrl = 'http://localhost:5174/auth';
    }
  } catch (error) {
    console.error('Unable to retrieve device information from the Server:', error);
    apiErrorMessage.value = 'Unable to retrieve device information from the Server';
    return;
  }

  // Step 2: Check if the device responds
  if (await probe(tokenInformation.value.deviceUrl, States.PROBING)) {
    completedStates.value.push(States.PROBING);
    currentState.value = null;

    console.log("✅ Device is online. Sending boot command to keep it awake...");
    await sendBootCommand();

    currentState.value = States.AWAITING_BOOT;
    console.log("⏳ Ensuring the device remains awake...");
    await sleep(PAUSE_UI);
    completedStates.value.push(States.AWAITING_BOOT);

    console.log("🚀 Launching Controller UI...");
    await launch(tokenInformation.value);
    return;
  }

  // Step 3: Device is offline - send boot command
  console.warn("❗ Device is offline. Sending boot command to wake it up...");
  await sendBootCommand();

  // Step 4: Polling until the device responds
  currentState.value = States.AWAITING_BOOT;
  pollingInterval.value = setInterval(async () => {
    const deviceUrl = tokenInformation.value?.deviceUrl;
    if (deviceUrl && await probe(deviceUrl, States.AWAITING_BOOT)) {
      console.log("✅ Device is now online after boot.");
      clearInterval(pollingInterval.value);
      currentState.value = null;

      if (tokenInformation.value) {
        console.log("🚀 Launching Controller UI...");
        await launch(tokenInformation.value);
      } else {
        console.error("❌ Missing token information. Cannot launch Controller UI.");
      }
      return;
    }
  }, PROBE_INTERVAL);
};

const probe = async (url: string, probeState: States.PROBING | States.AWAITING_BOOT): Promise<boolean> => {
  currentState.value = probeState;
  await sleep(PAUSE_UI);

  if (url.includes('localhost') && window.location.origin === 'http://localhost:5173') {
    console.warn("⚠️ Probing localhost: Make sure the Controller is running on http://localhost:5174/auth!");
  }

  let online = false;

  try {
    // Check basic connectivity
    const response = await fetch(url, { method: "GET" });
    const contentType = response.headers.get("Content-Type");

    if (response.ok && contentType?.includes("text/html")) {
      // Confirm its HTML content at the /auth page
      console.log(`✅ Connectivity check passed for ${url}. Content-Type: ${contentType}`);

      // Small buffer to ensure UI readiness
      console.log("⏳ Waiting 3 seconds for the Controller UI to load...");
      await sleep(WAIT_FOR_PI_UI);

      console.log(`✅ Controller UI assumed ready at ${url}`);
      online = true;
    } else {
      console.warn(`⚠️ Unexpected Content-Type: ${contentType}. Retrying...`);
    }
  } catch (error) {
    console.warn(`⏳ Probing failed at ${new Date().toLocaleTimeString()}`);
    console.error(`⚠️ Error occurred while probing ${url}:`, error);
  }

  return online;
};




const sendBootCommand = async (): Promise<void> => {
  currentState.value = States.SENDING_BOOT;
  console.log("🚀 Sending boot command to the device...");

  try {
    await client.createCameraByIdWakeupCommand({ cameraId: props.camera.id });
    console.log("✅ Boot command sent successfully (no response received).");
  } catch (error) {
    console.error("❌ Failed to send boot command:", error);
  }

  await sleep(PAUSE_UI); // Small delay to allow the boot command to take effect
  completedStates.value.push(States.SENDING_BOOT);
  currentState.value = null;
};

let configurator: Window | null;
const launch = async (tokenInformation: CreateCameraTokenResponse): Promise<void> => {
  currentState.value = States.LAUNCHING;
  await sleep(PAUSE_UI);

  if (!configurator) {
    configurator = window.open(tokenInformation.deviceUrl, 'configurator');
  }

  // Pause to ensure the opened window has the time to load before sending a message.
  await sleep(3000);

  configurator!.postMessage(JSON.parse(JSON.stringify(tokenInformation)), tokenInformation.deviceUrl);
}

const messageListener = (event: MessageEvent): void => {
  if (tokenInformation.value && !tokenInformation.value.deviceUrl.startsWith(event.origin)) return;

  if (event.data.status === 'success') {
    completedStates.value.push(States.LAUNCHING)
    currentState.value = undefined;
  } else if (event.data.status === 'error') {
    completedStates.value.push(States.LAUNCHING)
    currentState.value = States.ERROR;
    launchErrorMessage.value = event.data.message;
  }
}

async function closeModal(): Promise<void> {
  props.onClose()
}

onMounted(() => {
  init();
  window.addEventListener("message", messageListener);
})

onUnmounted(() => {
  if (pollingInterval.value) clearInterval(pollingInterval.value);
  window.removeEventListener("message", messageListener);
  console.warn('unmounting')
});

const sendingBootCommandClass = computed(() =>
  currentState.value === States.SENDING_BOOT ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING
);

const probingClass = computed(() =>
  currentState.value === States.PROBING ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING
);

const awaitingBootClass = computed(() =>
  currentState.value === States.AWAITING_BOOT ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING
);

const launchingClass = computed(() =>
  currentState.value === States.LAUNCHING ? ProgressIcon.IN_PROGRESS : ProgressIcon.PENDING
);
</script>

<template>
  <ModalComponent :visible="true"
                  heading-title="Launching Configurator"
                  @on-close="closeModal">
    <template v-if="apiErrorMessage" #modal-content>
      <span class="message-error">
        {{ apiErrorMessage }}
      </span>
    </template>
    <template v-else #modal-content>
      <div>
        <ArrowPathIcon v-if="currentState === States.PROBING || !completedStates.includes(States.PROBING)"
                       :aria-label="StateMessage.PROBING"
                       :class="probingClass" />
        <CheckIcon v-if="completedStates.includes(States.PROBING)"
                   :aria-label="StateMessage.PROBING"
                   :class="ProgressIcon.COMPLETE" />
        {{ StateMessage.PROBING }}
      </div>

      <div>
        <ArrowPathIcon v-if="currentState === States.SENDING_BOOT || !completedStates.includes(States.SENDING_BOOT)"
                       :aria-label="StateMessage.SENDING_BOOT"
                       :class="sendingBootCommandClass" />
        <CheckIcon v-if="completedStates.includes(States.SENDING_BOOT)"
                   :aria-label="StateMessage.SENDING_BOOT"
                   :class="ProgressIcon.COMPLETE" />
        {{ StateMessage.SENDING_BOOT }}
      </div>

      <div>
        <ArrowPathIcon v-if="currentState === States.AWAITING_BOOT || !completedStates.includes(States.AWAITING_BOOT)"
                       :aria-label="StateMessage.AWAITING_BOOT"
                       :class="awaitingBootClass" />
        <CheckIcon v-if="completedStates.includes(States.AWAITING_BOOT)"
                   :aria-label="StateMessage.AWAITING_BOOT"
                   :class="ProgressIcon.COMPLETE" />
        {{ StateMessage.AWAITING_BOOT }}
      </div>

      <div>
        <ArrowPathIcon v-if="currentState === States.LAUNCHING || !completedStates.includes(States.LAUNCHING)"
                       :aria-label="StateMessage.LAUNCHING"
                       :class="launchingClass" />
        <CheckIcon v-if="completedStates.includes(States.LAUNCHING) && currentState !== States.ERROR"
                   :aria-label="StateMessage.LAUNCHING"
                   :class="ProgressIcon.COMPLETE" />
        <XMarkIcon v-if="currentState === States.ERROR"
                   :aria-label="StateMessage.LAUNCHING"
                   :class="ProgressIcon.FAILED" />
        {{ StateMessage.LAUNCHING }}
        <span v-if="launchErrorMessage"
              :class="ProgressIcon.FAILED">
          ({{ launchErrorMessage }})
        </span>
      </div>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.progress-icon {
  width: 1.3rem;
  height: 1.2rem;

  &--pending {
    color: $black-opacity-25;
  }

  &--failed {
    color: $red-900;
  }

  &--in-progress {
    color: $orange-800;
    animation: rotate-360 0.75s linear infinite;
  }

  &--complete {
    color: $green-800;
  }
}
</style>
