<script setup lang="ts">
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import OrganisationUpdateForm from '@components/organisation_update/OrganisationUpdateForm.vue';
import { useApplicationStore } from '@stores/application';
import { storeToRefs } from 'pinia';
import OrganisationFlagsForm from '@components/organisation_update/OrganisationFlagsForm.vue';
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
</script>

<template>
  <Suspense>
    <template #default>
      <div>
        <ContainerCard>
          <OrganisationUpdateForm />
        </ContainerCard>
        <OrganisationFlagsForm v-if="adminMode" />
      </div>
    </template>

    <template #fallback>
      <Loading />
    </template>
  </Suspense>
</template>
