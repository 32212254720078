<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import { ButtonType, ButtonVariant, CardVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const route = useRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();
const viewId = route.params['id'] as string;
const isSubmitting = ref<boolean>(false);
const currentView = ref<View>();

const onImportViewSource = async (e: any): Promise<void> => {
  e.preventDefault();
  isSubmitting.value = true;
  const response = await viewStore.importViewImages(viewId);
  currentView.value = await viewStore.getViewById(viewId);
  isSubmitting.value = false;

  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully imported images to the view.',
    autoCloseMs: 3000,
  });
};

const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
};

onMounted(async () => {
  await fetchViewData();
});

</script>

<template>
  <ContainerCard v-if="applicationStore.adminMode" :variant="CardVariant.Dark" :has-border="true">
    <form @submit="onImportViewSource">
      <Heading :has-bottom-margin="true" level="3">
        View Source Import Admin Panel
      </Heading>
      <p>Import images from an AWS storage bucket for {{ currentView?.name }}.</p>
      <p>This view must be configured with an AWS source, and have no existing images.</p>
      <p>Note: Only the database records are created, the actual images will stay hosted on AWS.</p>

      <ButtonContainer>
        <ButtonComponent :disabled="!(currentView?.source?.provider === 'AWS') || currentView?.imageCount > 0"
                         :variant="ButtonVariant.Light"
                         :loading="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Import Images
        </ButtonComponent>
      </ButtonContainer>
    </form>
  </ContainerCard>
</template>
