<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, ref } from 'vue';
import Popper from 'vue3-popper';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useViewStore } from '@stores/view';
import ViewerCatalogue from './ViewerCatalogue.vue';
import ViewerFrame from './ViewerFrame.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ButtonVariant } from '@viewModels/enums';
import ModalComponent from '@components/ModalComponent.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { useApplicationStore } from '@stores/application';
import { IMAGE_FOCUS, useIrisViewerStore, VIEW_MODE } from '@stores/irisViewerStore';
import { useMobileDetection } from '@utils/isMobile';

const props = defineProps({
  view: {
    type: Object as PropType<client.View>,
    required: true,
  },
});

// Stores
const applicationStore = useApplicationStore();
const viewStore = useViewStore();
const irisStore = useIrisViewerStore();
const { datePickerIsOpen } = storeToRefs(viewStore);
const { isMobile } = useMobileDetection();

// Refs
const frameElementRef = ref<InstanceType<typeof ViewerFrame>>();

// Controls
const controls = { visible: true };
const height = ref(601);
const minScale = 1;
const maxScale = 10;
const menu = { visible: true };
const overlay = ref(false);
const showMenuLeft = ref(false);
const showMenuRight = ref(false);
const wall = ref({ fullscreen: false });
const zoom = ref({ scale: 1, x: 0, y: 0 });

onMounted(async () => {
  await irisStore.init(props.view.id);
  getViewerHeight();
  window.addEventListener('resize', () => {
    getViewerHeight();
  });
});

onUnmounted(() => {
  irisStore.$reset();
});

const frameStyle = computed(() => {
  const position = overlay.value ? 'absolute' : 'relative';
  const size = {
    height: overlay.value ? '100%' : 'auto',
    width: '100%',
  };
  const border = {};
  return {
    ...border,
    position,
    ...size,
  };
});

const zoomControls = computed(() => {
  return {
    fit: {
      action: 'reset',
      icon: IconName.ViewfinderCircleIcon,
      title: 'Reset Zoom',
    },
    zoomOut: {
      action: 'out',
      icon: IconName.MagnifyingGlassMinusIcon,
      title: 'Zoom Out',
    },
    zoomIn: {
      action: 'in',
      icon: IconName.MagnifyingGlassPlusIcon,
      title: 'Zoom In',
    },
    fullscreen: {
      action: 'fullscreen',
      icon: wall.value.fullscreen ? IconName.ArrowsPointingInIcon : IconName.ArrowsPointingOutIcon,
      title: wall.value.fullscreen ? 'Exit Fullscreen' : 'Fullscreen',
    },
  };
});

const downloadImageLink = (): void => {
  if (!irisStore.selectedImage) return;

  // Construct the download URL
  const url = `${irisStore.selectedImage.sourceURL}?dl=${irisStore.selectedImage.originalFileName}`;

  // Trigger the download
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.setAttribute('download', irisStore.selectedImage.originalFileName || '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  applicationStore.publishSuccessNotification({
    text: 'Image downloading, please check your browser’s downloads folder',
    autoCloseMs: 3000,
  });
};

function getViewerHeight(): void {
  const getViewer = setInterval(() => {
    const viewer: HTMLDivElement | null = document.querySelector('div.viewer');
    if (viewer) {
      height.value = viewer.offsetHeight;
      clearInterval(getViewer);
    }
  });
}

function toggleFullscreen(): void {
  wall.value.fullscreen = !wall.value.fullscreen;
  frameElementRef.value?.init();
}

function toggleMenu(side: string): void {
  if (side === 'left') {
    showMenuLeft.value = !showMenuLeft.value;
    showMenuRight.value = false;
  } else if (side === 'right') {
    showMenuRight.value = !showMenuRight.value;
    showMenuLeft.value = false;
  }
}

const isDisabled = (action: string): boolean => {
  switch (action) {
    case 'in':
      return zoom.value.scale >= maxScale;
    case 'out':
      return zoom.value.scale <= minScale;
    case 'reset':
      return false; // Reset button is always enabled
    default:
      return false; // No other actions should be disabled
  }
};

function zoomAction(action: string): void {
  if (!frameElementRef.value) return;

  switch (action) {
    case 'fullscreen':
      toggleFullscreen();
      break;
    case 'in':
      zoomIn();
      break;
    case 'out':
      zoomOut();
      break;
    case 'reset':
      frameElementRef.value.init(); // Reset the frame
      zoom.value = { scale: 1, x: 0, y: 0 }; // Reset zoom state to default
      break;
  }
}

function zoomChange({ scale, x, y }: { scale: number; x: number; y: number }): void {
  if (!frameElementRef.value) return;

  // Use setZoom to handle scale logic
  setZoom(scale);

  // Update x and y separately
  zoom.value = { ...zoom.value, x, y };
  frameElementRef.value.setTransform({ scale, x, y });
}

function setZoom(newScale: number): void {
  if (!frameElementRef.value) return;

  // Clamp the scale between minScale and maxScale
  const clampedScale = Math.min(Math.max(newScale, minScale), maxScale);

  frameElementRef.value.scaleTo(clampedScale, {
    originX: '50%',
    originY: '50%',
    relativeTo: 'container',
    allowChangeEvent: true,
  });

  zoom.value = { ...zoom.value, scale: clampedScale }; // Update zoom state
}

function zoomIn(): void {
  setZoom(zoom.value.scale * 1.38);
}

function zoomOut(): void {
  setZoom(zoom.value.scale / 1.38);
}
</script>

<template>
  <!-- Different styles throughout for Public View and View Viewer Page -->
  <div class="viewer" :class="{ 'viewer--fullscreen': wall.fullscreen }">
    <aside v-if="!isMobile" class="viewer__sidebar" :class="{ 'viewer__sidebar--is-open': datePickerIsOpen }">
      <!-- Date Selection sidebar for Desktop -->
      <ViewerCatalogue v-if="!wall.fullscreen" />
    </aside>

    <!-- New Controls Placement -->
    <section class="viewer__content">
      <!-- Menu Controls -->
      <div class="viewer__menu">
        <div class="viewer__menu--left">
          <div v-if="menu.visible" class="viewer__menu--btn-container">
            <ButtonComponent class="is-hidden-desktop"
                             :variant="ButtonVariant.Primary"
                             :is-icon-btn="true"
                             aria-label="Show Image Controls"
                             :icon-style="IconStyle.Solid"
                             :icon-name="IconName.WrenchScrewdriverIcon"
                             @click="toggleMenu('left')" />

            <div :class="['display-contents', { 'is-hidden-mobile': !showMenuLeft }]">
              <Popper content="First available image"
                      hover
                      arrow
                      offset-skid="0"
                      placement="bottom">
                <div>
                  <ButtonComponent :variant="ButtonVariant.Primary"
                                   :is-icon-btn="true"
                                   aria-label="First available image"
                                   :icon-style="IconStyle.Solid"
                                   :icon-name="IconName.BackwardIcon"
                                   @click="irisStore.setSelectedImageToFirst()" />
                </div>
              </Popper>

              <Popper content="Latest image"
                      hover
                      arrow
                      offset-skid="0"
                      placement="bottom">
                <div>
                  <ButtonComponent :variant="ButtonVariant.Primary"
                                   :is-icon-btn="true"
                                   aria-label="Latest image"
                                   :icon-style="IconStyle.Solid"
                                   :icon-name="IconName.ForwardIcon"
                                   @click="irisStore.setSelectedImageToLast()" />
                </div>
              </Popper>

              <Popper content="Single image"
                      hover
                      arrow
                      offset-skid="30"
                      placement="bottom">
                <div>
                  <ButtonComponent v-if="!isMobile"
                                   :variant="ButtonVariant.Primary"
                                   :disabled="irisStore.viewMode === VIEW_MODE.SINGLE"
                                   :is-icon-btn="true"
                                   aria-label="Single image"
                                   :icon-style="IconStyle.Solid"
                                   :icon-name="IconName.PhotoIcon"
                                   @click="() => irisStore.resetViewMode()" />
                </div>
              </Popper>

              <Popper content="Compare images side-by-side"
                      hover
                      arrow
                      offset-skid="30"
                      placement="bottom">
                <div>
                  <ButtonComponent v-if="!isMobile"
                                   :variant="ButtonVariant.Primary"
                                   :disabled="irisStore.viewMode === VIEW_MODE.COMPARE"
                                   :is-icon-btn="true"
                                   aria-label="Compare images side-by-side"
                                   :icon-style="IconStyle.Solid"
                                   :icon-name="IconName.ViewColumnsIcon"
                                   @click="() => irisStore.startCompareMode()" />
                </div>
              </Popper>

              <Popper content="Compare images overlaid"
                      hover
                      arrow
                      offset-skid="30"
                      placement="bottom">
                <div>
                  <ButtonComponent v-if="!isMobile"
                                   :variant="ButtonVariant.Primary"
                                   :disabled="irisStore.viewMode === VIEW_MODE.OVERLAY"
                                   :is-icon-btn="true"
                                   aria-label="Compare images side-by-side"
                                   :icon-style="IconStyle.Solid"
                                   :icon-name="IconName.Square2StackIcon"
                                   @click="() => irisStore.startOverlayMode()" />
                </div>
              </Popper>

              <Popper content="Stack images"
                      hover
                      arrow
                      offset-skid="30"
                      placement="bottom">
                <div>
                  <ButtonComponent v-if="!isMobile"
                                   :variant="ButtonVariant.Primary"
                                   :disabled="irisStore.viewMode === VIEW_MODE.BLEND"
                                   :is-icon-btn="true"
                                   aria-label="Compare images side-by-side"
                                   :icon-style="IconStyle.Solid"
                                   :icon-name="IconName.CircleStackIcon"
                                   @click="() => irisStore.startBlendMode()" />
                </div>
              </Popper>

              <Popper content="Download selected image"
                      hover
                      arrow
                      placement="bottom">
                <div>
                  <ButtonComponent v-if="!overlay"
                                   :variant="ButtonVariant.Primary"
                                   :is-icon-btn="true"
                                   :icon-name="IconName.ArrowDownTrayIcon"
                                   :icon-style="IconStyle.Solid"
                                   aria-label="Download selected image"
                                   @click="downloadImageLink()" />
                </div>
              </Popper>
            </div>
          </div>
        </div>

        <div v-if="menu.visible" class="viewer__menu--right">
          <div v-if="controls.visible" class="viewer__menu--btn-container">
            <div :class="['display-contents', { 'is-hidden-mobile': !showMenuRight }]">
              <Popper content="Double-click the image to center and zoom in on the point clicked. Scroll using mousewheel or touchpad to zoom on the center of the frame."
                      hover
                      arrow
                      placement="bottom"
                      multilined
                      class="is-hidden-mobile">
                <div>
                  <ButtonComponent :variant="ButtonVariant.Primary"
                                   :is-icon-btn="true"
                                   :icon-name="IconName.InformationCircleIcon"
                                   :icon-style="IconStyle.Solid"
                                   style="cursor: default"
                                   aria-label="Zoom Help" />
                </div>
              </Popper>

              <Popper v-for="(control, key) in zoomControls"
                      :key="key"
                      :content="control.title"
                      hover
                      arrow
                      placement="bottom">
                <div>
                  <ButtonComponent :variant="ButtonVariant.Primary"
                                   :is-icon-btn="true"
                                   :icon-name="control.icon"
                                   :icon-style="IconStyle.Solid"
                                   :aria-label="control.title"
                                   :disabled="control.action !== 'reset' && isDisabled(control.action)"
                                   @click="zoomAction(control.action)" />
                </div>
              </Popper>
            </div>

            <ButtonComponent class="is-hidden-desktop"
                             :variant="ButtonVariant.Primary"
                             :is-icon-btn="true"
                             :icon-name="IconName.MagnifyingGlassPlusIcon"
                             :icon-style="IconStyle.Solid"
                             aria-label="Show Zoom Controls"
                             @click="toggleMenu('right')" />
          </div>
        </div>
      </div>

      <!-- Viewer Wall -->
      <div class="viewer__wall">
        <!-- Viewer Frame -->
        <ViewerFrame v-if="irisStore.selectedImage"
                     ref="frameElementRef"
                     :view-name="view.name"
                     :mode="irisStore.viewMode"
                     :left-image="irisStore.getLeftSelectedImage!"
                     :right-image="irisStore.getRightSelectedImage"
                     :image-date="irisStore.selectedImage.formattedDateTime!"
                     :max-scale="maxScale"
                     :overlaid="overlay"
                     :style="frameStyle"
                     :opacity="Number(irisStore.viewModeCompareSettings.opacity)"
                     @zoom-change="zoomChange">
          <!-- Left / Right Image Buttons -->
          <template #viewer-frame-buttons>
            <div v-if="irisStore.hasImages && !overlay" class="">
              <div class="viewer__wall--left-btn">
                <ButtonComponent v-if="irisStore.hasPreviousImage"
                                 :variant="ButtonVariant.Primary"
                                 :is-icon-btn="true"
                                 aria-label="Previous image"
                                 :icon-style="IconStyle.Solid"
                                 :icon-name="IconName.ChevronLeftIcon"
                                 @click="irisStore.setSelectedImageToPrevious()" />
              </div>
              <div class="viewer__wall--right-btn">
                <ButtonComponent v-if="irisStore.hasNextImage"
                                 :variant="ButtonVariant.Primary"
                                 :is-icon-btn="true"
                                 aria-label="Next image"
                                 :icon-style="IconStyle.Solid"
                                 :icon-name="IconName.ChevronRightIcon"
                                 @click="irisStore.setSelectedImageToNext()" />
              </div>
            </div>
          </template>
        </ViewerFrame>
      </div>

      <!-- Left/Right image selection buttons -->
      <Transition name="slide-fade">
        <div v-if="irisStore.hasComparisonMode" class="viewer__menu--comparison-buttons">
          <ButtonComponent :variant="irisStore.viewModeCompareSettings.focusedImage == IMAGE_FOCUS.LEFT ? ButtonVariant.Danger : ButtonVariant.Primary"
                           @click="() => irisStore.setImageFocus(IMAGE_FOCUS.LEFT)">
            {{ irisStore.isCompareMode ? 'Left' : '' }}
            {{ irisStore.isOverlayMode ? 'Background' : '' }}
            {{ irisStore.isBlendMode ? 'Background' : '' }}
          </ButtonComponent>

          <!-- Blender Opacity Slider -->
          <input v-if="irisStore.isBlendMode"
                 id="image-blender"
                 v-model="irisStore.viewModeCompareSettings.opacity"
                 name="image-blender"
                 type="range"
                 :step="1">

          <ButtonComponent :variant="irisStore.viewModeCompareSettings.focusedImage == IMAGE_FOCUS.RIGHT ? ButtonVariant.Danger : ButtonVariant.Primary"
                           @click="() => irisStore.setImageFocus(IMAGE_FOCUS.RIGHT)">
            {{ irisStore.isCompareMode ? 'Right' : '' }}
            {{ irisStore.isOverlayMode ? 'Overlay' : '' }}
            {{ irisStore.isBlendMode ? 'Foreground' : '' }}
          </ButtonComponent>
        </div>
      </Transition>
    </section>
  </div>

  <!-- Date Selection Modal for Mobile -->
  <ModalComponent v-if="datePickerIsOpen && isMobile"
                  :visible="datePickerIsOpen"
                  heading-title="Date & Image Selection"
                  @on-close="datePickerIsOpen = false">
    <template #modal-content>
      <ViewerCatalogue v-if="!wall.fullscreen" />
    </template>
    <template #modal-footer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       @click="datePickerIsOpen = false">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss">
@use '@scss/variables' as *;

// This file has crossover styles for the Viewer Page and Public Viewer Page.
:root {
  --popper-theme-background-color: #323232;
  --popper-theme-background-color-hover: #323232;
  --popper-theme-border-radius: 5px;
  --popper-theme-border-style: solid;
  --popper-theme-border-width: 0px;
  --popper-theme-box-shadow: 0;
  --popper-theme-padding: 10px;
  --popper-theme-text-color: #fff;
}

.popper {
  font-size: 0.8em;
}

// Public Viewer Page No need to hide the side navigation menu as it is not visible
.public-viewer--container {
  & .viewer.viewer--fullscreen {
    @media screen and (min-width: $breakpoint-lg) {
      left: 0;
    }
  }
}

.viewer {
  position: relative;
  z-index: 11;
  display: flex;
  gap: 30px;
  height: auto;
  max-height: calc(100vh - 300px);

  @media screen and (min-width: $breakpoint-lg) {
    min-height: calc(100vh - 300px);
  }

  &--fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;

    @media screen and (min-width: $breakpoint-lg) {
      left: -230px; // 230px is the width of the side navigation menu
    }

    & .viewer__sidebar {
      display: none;
    }

    .viewer__wall {
      aspect-ratio: auto;
    }

    .viewer__content {
      display: flex;
      flex-grow: 1;
      padding: clamp($gap-mobile, 2vw, $gap-desktop);
      background-color: $neutral-50;
    }
  }

  &__sidebar {
    display: none;

    @media screen and (min-width: $breakpoint-lg) {
      display: flex;
      flex-direction: column;
      width: 250px;
      background-color: var(--primary-color, $primary-color);
      border-radius: 5px;
    }

    &--public-view {
      left: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  &__wall {
    position: relative;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $neutral-50;

    // We need to set aspect-ratio for mobile
    @media screen and (max-width: $breakpoint-lg) {
      aspect-ratio: 3 / 2;
    }

    &--left-btn {
      position: absolute;
      top: 50%;
      left: 15px;
    }

    &--right-btn {
      position: absolute;
      top: 50%;
      right: 15px;
    }
  }

  &__menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;

    &--left {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--right {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--btn-container {
      display: flex;
      column-gap: 5px;
      align-items: center;

      & .display-contents {
        display: contents;
      }

      @media screen and (width < ($breakpoint-xxl + 100px)) {
        .is-hidden-mobile {
          display: none;
        }
      }

      @media screen and (width > ($breakpoint-xxl + 100px)) {
        .is-hidden-desktop {
          display: none;
        }
      }
    }

    &--comparison-buttons {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      z-index: 0; // For the Transition Effect

      button {
        &.is-active {
          background: var(--secondary-color, $secondary-color);
        }
      }
    }
  }
}
</style>

<!--Transition Effects-->
<style scoped lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 300ms ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
